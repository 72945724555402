package com.nikolastojiljkovic.algot.client.components.monaco

import scala.scalajs.js

case class TerminalMessage
(
  terminalId: String, message: String, isError: Boolean
) {
  def this(a: Any) = {
    this(
      a.asInstanceOf[js.Dynamic].selectDynamic("terminalId").asInstanceOf[String],
      a.asInstanceOf[js.Dynamic].selectDynamic("message").asInstanceOf[String],
      a.asInstanceOf[js.Dynamic].selectDynamic("isError").asInstanceOf[Boolean]
    )
  }
}