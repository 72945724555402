package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.{Codec, StringAsIsCodec}
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinSplitLayout.vaadinSplitLayoutMod.SplitLayout as VaadinSplitLayout

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object SplitLayout {

  facades.vaadinSplitLayout.vaadinSplitLayoutRequire

  type Ref = dom.html.Element with VaadinSplitLayout
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = SplitLayout.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-split-layout")

  val id: Prop[String] = idAttr

  enum Orientation:
    case horizontal, vertical

  val orientation: HtmlAttr[Orientation] = customHtmlAttr("orientation", summon[Codec[Orientation, String]])

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (SplitLayout)): _*)
  }
}