package com.nikolastojiljkovic.algot.client.components.vaadin

import com.nikolastojiljkovic.algot.client.components.vaadin.AppLayout.PrimarySection
import com.raquo.domtypes.generic.codecs.*
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinAppLayout.vaadinAppLayoutVaadinDrawerToggleMod.DrawerToggle as VaadinDrawerToggle
import org.scalablytyped.runtime.StObject

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object DrawerToggle {

  @JSImport("@vaadin/app-layout/theme/lumo/vaadin-drawer-toggle", JSImport.Namespace)
  @js.native
  object lumoTheme extends StObject

  lumoTheme

  type Ref = dom.html.Element with VaadinDrawerToggle
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = DrawerToggle.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-drawer-toggle")

  val slot: HtmlAttr[PrimarySection] = customHtmlAttr("slot", summon[Codec[PrimarySection, String]])

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (DrawerToggle)): _*)
  }
}