package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinAppLayout.vaadinAppLayoutMod.AppLayout as VaadinAppLayout

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object AppLayout extends Laminar {

  facades.vaadinAppLayout.vaadinAppLayoutRequire

  type REF = dom.html.Element with VaadinAppLayout
  type El = ReactiveHtmlElement[REF]
  type ModFunction = AppLayout.type => Mod[El]

  private val tag = customHtmlTag[REF]("vaadin-app-layout")

  val id: Prop[String] = idAttr

  enum PrimarySection:
    case navbar, drawer

  val primarySection: HtmlAttr[PrimarySection] = customHtmlAttr("primary-section", summon[Codec[PrimarySection, String]])
  val drawerOpened: Prop[Boolean] = customProp("drawerOpened", BooleanAsIsCodec)

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (AppLayout)): _*)
  }
}