package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.{Codec, StringAsIsCodec}
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinButton.vaadinButtonMod.Button as VaadinButton
import org.scalablytyped.runtime.StObject

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object Button {

//  @JSImport("@vaadin/button/theme/material/vaadin-button", JSImport.Namespace)
//  @js.native
//  object materialTheme extends StObject
//
//  @JSImport("@vaadin/button/theme/lumo/vaadin-button", JSImport.Namespace)
//  @js.native
//  object lumoTheme extends StObject
//
//  @JSImport("@vaadin/button/src/vaadin-button", JSImport.Namespace)
//  @js.native
//  object noTheme extends StObject
//
//  noTheme

  facades.vaadinButton.vaadinButtonRequire

  type Ref = dom.html.Element with VaadinButton
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = Button.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-button")

  val id: Prop[String] = idAttr

  val label: HtmlAttr[String] = customHtmlAttr("label", StringAsIsCodec)

  val theme: HtmlAttr[String] = customHtmlAttr("theme", StringAsIsCodec)

  val onMouseOver: EventProp[dom.MouseEvent] = customEventProp("mouseover")

  object styles {
    val mdcThemePrimary: Style[String] = customStyle("--mdc-theme-primary")
  }

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (Button)): _*)
  }
}