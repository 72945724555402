package com.nikolastojiljkovic.algot.client.components.monaco

import com.nikolastojiljkovic.scalajs.facades
import facades.ScalaLanguage
import com.raquo.airstream.ownership.Owner
import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import com.raquo.laminar.Laminar
import facades.codingameMonacoLanguageclient.connectionMod.{ConnectionCloseHandler, ConnectionErrorHandler, IConnection, IConnectionProvider}
import facades.vscodeLanguageclient.clientMod.{CloseAction, ErrorAction, ErrorHandler, LanguageClientOptions, StateChangeEvent}
import facades.vscodeJsonrpc.cancellationMod.CancellationTokenSource
import facades.codingameMonacoLanguageclient.mod.MonacoLanguageClient
import facades.codingameMonacoLanguageclient.monacoLanguageClientMod.MonacoLanguageClient.Options as MonacoLanguageClientOptions
import facades.codingameMonacoLanguageclient.servicesMod.{OutputChannel, Window}
import facades.monacoEditorCore.mod.languages.{ILanguageExtensionPoint, IMonarchLanguage, LanguageConfiguration}
import facades.vscodeJsonrpc.connectionMod.MessageConnection
import facades.codingameMonacoLanguageclient.monacoServicesMod.MonacoServices
import facades.codingameMonacoLanguageclient.anon.Typeofmonaco
import facades.monacoEditorCore.mod as monaco
import facades.monacoEditorCore.monacoEditorCoreRequire as monacoExports
import facades.monacoEditorCore.monacoEditorCoreStrings.*
import facades.monacoEditorCore.mod.editor.*
import facades.normalizeUrl.mod.default as normalizeUrl
import facades.reconnectingWebsocket.mod.default as ReconnectingWebsocket
import facades.codingameMonacoJsonrpc.mod.toSocket
import facades.codingameMonacoJsonrpc.mod.createWebSocketConnection
import facades.vscodeJsonrpc.connectionMod.Logger
import facades.codingameMonacoJsonrpc.loggerMod.ConsoleLogger
import facades.vscodeJsonrpc.disposableMod.Disposable
import io.laminext.domext.ResizeObserverEntry
import japgolly.scalajs.react.Callback
import org.scalajs.dom
import org.scalajs.dom.WebSocket
import facades.codingameMonacoLanguageclient.consoleWindowMod.ConsoleWindow

import scala.scalajs.js
import scala.scalajs.js.Promise

object MonacoLanguageClientConnection {
  enum State:
    case Stopped, Starting, Running

  import com.raquo.airstream.state.{StrictSignal, Var}
  import com.raquo.airstream.core.{EventStream, Signal}
  import com.raquo.airstream.eventbus.EventBus
  import com.nikolastojiljkovic.scalajs.facades.vscodeLanguageclient.clientMod.State as VsClientState

  private def conv(vsState: VsClientState): State = vsState match {
    case VsClientState.Stopped => State.Stopped
    case VsClientState.Starting => State.Starting
    case VsClientState.Running => State.Running
  }

  private lazy val state: Var[State] = Var(State.Stopped)
  private lazy val languageClient: Var[Option[MonacoLanguageClient]] = Var(None)
  lazy val (signal: Signal[State], languageClientSignal: Signal[Option[MonacoLanguageClient]]) = {
    init
    (state.signal, languageClient.signal)
  }
  private val (stream, terminalMessageCallback) = EventStream.withCallback[TerminalMessage]
  lazy val terminalMessageStream: EventStream[TerminalMessage] = stream

  private def createUrl(path: String): String = {
    val location = dom.document.location
    val protocol = if (location.protocol == "https:") "wss" else "ws"
    normalizeUrl(s"$protocol://${location.host}${location.pathname}$path")
  }

  private def createWebSocket(url: String): WebSocket = {
    new ReconnectingWebsocket(url).asInstanceOf[WebSocket]
  }

  private def createLanguageClient(connection: MessageConnection): MonacoLanguageClient = {
    println("Creating language client")

    val errorHandler = new js.Object() {
      def error(): ErrorAction = ErrorAction.Continue

      def closed(): CloseAction = CloseAction.DoNotRestart
    }.asInstanceOf[ErrorHandler]

    val connectionProvider = new js.Object() {
      def get(errorHandler: ConnectionErrorHandler, closeHandler: ConnectionCloseHandler): Promise[IConnection] = {
        Promise.resolve(
          facades.codingameMonacoLanguageclient.mod.createConnection(
            connection,
            errorHandler,
            closeHandler
          )
        )
      }
    }.asInstanceOf[IConnectionProvider]

    new MonacoLanguageClient(MonacoLanguageClientOptions(
      LanguageClientOptions()
        .setDocumentSelector(js.Array("scala"))
        .setErrorHandler(errorHandler),
      connectionProvider,
      "Scala Language Client"
    ))
  }

  private def init = {
    println("Initializing Monaco...")

    val window = Window(
      showMessage = (messageType: com.nikolastojiljkovic.scalajs.facades.vscodeLanguageserverProtocol.protocolMod.MessageType, message: String, actions: Any) => {
        println(s"SSSSS: $messageType $message")
        js.Promise.resolve(js.undefined)
      }
    ).setCreateOutputChannel((name: String) => {
      val __obj = js.Dynamic.literal(
        append = js.Any.fromFunction1((value: String) => {
          println(s"append $name $value")
        }),
        appendLine = js.Any.fromFunction1((value: String) => {
          println(s"appendLine $name $value")
        }),
        show = js.Any.fromFunction0(() => {
          println(s"show $name")
        }),
        show = js.Any.fromFunction1((preserveFocus: Boolean) => {
          println(s"show $name $preserveFocus")
        }),
        dispose = js.Any.fromFunction0(() => {
          println(s"dispose $name")
        }),
      )
      __obj.asInstanceOf[OutputChannel]
    }).asInstanceOf[ConsoleWindow]

    MonacoServices.install(
      monacoExports.asInstanceOf[Typeofmonaco]
    ).setWindow(window)

    val ScalaLanguageExtensionPoint = {
      //      val language = jsObject[ILanguageExtensionPoint]
      //      language.id = "scala"
      //      language.extensions = js.Array(".scala", ".sc")
      //      language.aliases = js.Array("Scala")
      //      language.mimetypes = js.Array("text/plain")
      //      language

      ILanguageExtensionPoint("scala")
        .setExtensions(js.Array(".scala", ".sc"))
        .setAliases(js.Array("Scala"))
        .setMimetypes(js.Array("text/plain"))
    }
    monaco.languages.register(ScalaLanguageExtensionPoint)
    monaco.languages.setMonarchTokensProvider(
      ScalaLanguageExtensionPoint.id,
      ScalaLanguage.language
    )

    monaco.languages.setLanguageConfiguration(
      ScalaLanguageExtensionPoint.id,
      ScalaLanguage.conf
    )

    val url = createUrl("/lsp")
    val webSocket = createWebSocket(url)
    state.update(_ => State.Starting)

    /*
    import facades.codingameMonacoJsonrpc.mod.listen
    import facades.codingameMonacoJsonrpc.anon.Logger as ListenConf
    listen(ListenConf(
      connection => Callback {
        println("Connection started")
        // create and start the language client
        val languageClient = createLanguageClient(connection)
        val disposable = languageClient.start().asInstanceOf[com.nikolastojiljkovic.scalajs.facades.vscodeJsonrpc.disposableMod.Disposable]
        connection.onClose(
          _ => {
            println("Connection closed")
            disposable.dispose()
          }
        )
      },
      webSocket
    ))
    */

    // @todo: expose stream of connection state
    webSocket.onopen = (evt: dom.Event) => {
      // websocket helper wrap
      val socket = toSocket(webSocket)
      // monaco json-rpc wrapper
      val connection = createWebSocketConnection(socket, new ConsoleLogger)
      // monaco language client
      val client = createLanguageClient(connection)
      languageClient.update(_ => Some(client))
      val disposable = client.start().asInstanceOf[Disposable]
      client.onDidChangeState((evt: StateChangeEvent) => {
        val s = conv(evt.newState)
        state.update(_ => s)
        if (s == State.Running) {
          client.onNotification("terminal/out", r => {
            try {
              val terminalMessage = new TerminalMessage(r)
              terminalMessageCallback(terminalMessage)
            } catch {
              case t: Throwable =>
                t.printStackTrace()
            }
          })
        }
      }, js.undefined, js.undefined)
      connection.onClose(
        _ => {
          println("Connection closed")
          disposable.dispose()
        }
      )
      ()
    }

    println("Monaco initialization completed.")
  }
}
