package com.nikolastojiljkovic.algot.client

import com.nikolastojiljkovic.scalajs.facades
import com.nikolastojiljkovic.algot.client.components.*
import com.nikolastojiljkovic.algot.client.components.monaco.*
import com.nikolastojiljkovic.algot.client.components.vaadin.*
import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import com.raquo.laminar.Laminar

import scala.scalajs.js
//import com.raquo.laminar.api.L.{*, given}
import com.raquo.laminar.api.{*, given}
import org.scalajs.dom

import scala.scalajs.js
import scala.scalajs.js.annotation.JSExportAll
import scala.util.{Failure, Success}

import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*

import monocle.syntax.all._
import io.circe.syntax._
import io.circe.parser.decode
import io.circe.Codec

object ClientApp extends Laminar {

  enum UITestState:
    case ShowUPlot, ShowLightweightCharts, ShowHighcharts, ShowChartsJS, HideEditor

  case class AppState
  (
    theme: String = "",
    editorWidth: BigDecimal = BigDecimal(50),
    chartWidth: BigDecimal = BigDecimal(50),
    chartHeight: BigDecimal = BigDecimal(50),
    terminalHeight: BigDecimal = BigDecimal(50),
    testState: UITestState = UITestState.ShowUPlot
  ) derives Codec.AsObject

  enum Command:
    case ResizeEditor(width: BigDecimal)
    case ResizeChart(width: Option[BigDecimal] = None, height: Option[BigDecimal] = None)
    case ResizeTerminal(height: BigDecimal)
    case ToggleTestState(show: Option[UITestState] = None)
    case ToggleTheme(theme: Option[String] = None)
    case NotImplementedAlert(msg: String)
    case Noop

  @js.native
  trait CustomEventDetail extends js.Object {
    def value: js.UndefOr[ActionWrap] = js.native
  }

  @js.native
  trait ActionWrap extends js.Object {
    def text: js.UndefOr[String] = js.native

    def cmd: js.UndefOr[Command] = js.native
  }

  private val appStateStorageKey = "appState"

  def main(args: Array[String]): Unit = {
    // read initial state from local storage
    val state = Var({
      Option(dom.window.localStorage.getItem(appStateStorageKey)).flatMap(str => {
        decode[AppState](str).toOption
      }).getOrElse(AppState())
    })

    import Command._
    val commandObserver = Observer[Command] {
      case ResizeEditor(width) =>
        state.update(
          _.focus(_.editorWidth).modify(_ => width)
        )

      case ResizeChart(Some(width), Some(height)) =>
        state.update(
          _.focus(_.chartWidth).modify(_ => width)
            .focus(_.chartHeight).modify(_ => height)
        )

      case ResizeChart(Some(width), _) =>
        state.update(
          _.focus(_.chartWidth).modify(_ => width)
        )

      case ResizeChart(_, Some(height)) =>
        state.update(
          _.focus(_.chartHeight).modify(_ => height)
        )

      case ResizeTerminal(height) =>
        state.update(
          _.focus(_.terminalHeight).modify(_ => height)
        )

      case ToggleTestState(Some(testState)) =>
        state.update(
          _.focus(_.testState).modify(_ => testState)
        )

      case ToggleTestState(None) =>
        state.update(
          _.focus(_.testState).modify((currentState: UITestState) =>
            val nextOrdinal = (currentState.ordinal + 1) % UITestState.values.length
              UITestState
            .fromOrdinal(nextOrdinal)
          )
        )

      case ToggleTheme(Some(theme)) =>
        state.update(
          _.focus(_.theme).modify(_ => theme)
        )

      case ToggleTheme(None) =>
        state.update(
          _.focus(_.theme).modify(currentTheme => if (currentTheme == "dark") "" else "dark")
        )

      case Noop =>
        state

      case NotImplementedAlert(msg) =>
        import facades.vaadinNotification.mod.*

        facades.vaadinNotification.vaadinNotificationRequire

        Notification.show(msg, ShowOptions()
          .setPosition(NotificationPosition.`bottom-center`)
          .setDuration(2000)
          .setTheme("error")
        )
        state
    }

    // write state to local storage on every update
    val stateObserver = Observer[AppState](ev => {
      dom.window.localStorage.setItem(appStateStorageKey, ev.asJson.toString)
    })
    state.toObservable.addObserver(stateObserver)(unsafeWindowOwner)

    val theme = htmlAttr("theme", StringAsIsCodec)

    val rootElement = main(
      theme <-- state.signal.map(_.theme),
      AppLayout(
        _.primarySection := AppLayout.PrimarySection.navbar,
        _.drawerOpened := false,
        _ => DrawerToggle(
          _.slot := AppLayout.PrimarySection.navbar,
        ),
        _ => Tabs(
          _.slot := AppLayout.PrimarySection.drawer,
          _.orientation := Tabs.Orientation.vertical,
          _ => Tab(
            _ => a(
              Icon(_.icon := "vaadin:dashboard"),
              span("Dashboard")
            )
          ),
          _ => Tab(
            _ => a(
              Icon(_.icon := "vaadin:list"),
              span("Tasks")
            )
          ),
          _ => Tab(
            _ => a(
              Icon(_.icon := "vaadin:package"),
              span("Code")
            )
          ),
          _ => Tab(
            _ => a(
              Icon(_.icon := "vaadin:chart"),
              span("Analytics")
            )
          ),
          // vaadin:package
          // vaadin:records
        ),
        _ => h3(
          slot(AppLayout.PrimarySection.navbar.toString),
          "ALGOT",
          /*
          Button(
            _.theme := "tertiary icon",
            _ => Icon(
              _.icon <-- state.signal.map(_.theme).map {
                case "dark" => "vaadin:moon"
                case _ => "vaadin:sun-o"
              },
            ),
            _ => onClick.map(_ => ToggleTheme()) --> commandObserver,
          ),
          Button(
            _.theme := "tertiary icon",
            _ => Icon(
              _.icon := "vaadin:bolt",
            ),
            _ => onClick.map(_ => ToggleTestState()) --> commandObserver,
          )
          */
        ),
        _ => SplitLayout(
          _.orientation := SplitLayout.Orientation.horizontal,
          _ => height := "100%",
          _ => div(
            height := "100%",
            minWidth := "200px",
            overflow := "visible",
            //              flex := s"1 1 ${state.now().editorWidth}px",
            flex <-- state.signal.map(_.editorWidth).map(w => s"1 1 ${w}px"),
            resizeObserver --> commandObserver.contramap { (entry: ResizeObserverEntry) =>
              val selfWidth = entry.contentRect.width
              ResizeEditor(selfWidth)
            },
            VerticalLayout(
              _ => minHeight := "100%",
              _ => maxHeight := "100%",
              _ => HorizontalLayout(
                _ => width := "100%",
                _ => padding := "0 var(--lumo-space-xs)",
                _ => cls := "border-b border-contrast-10",
                _ => MenuBar(
                  _ => flexGrow := "1",
                  _ => overflow := "hidden",
                  _.theme := "tertiary",
                  _.onItemSelected --> commandObserver.contramap { (evt: dom.CustomEvent) =>
                    val detail = evt.detail.asInstanceOf[CustomEventDetail]
                    val cmd = for {
                      v <- detail.value
                      e <- v.cmd
                    } yield e
                    val text = for {
                      v <- detail.value
                      t <- v.text
                    } yield t
                    cmd.toOption.getOrElse(NotImplementedAlert(s"""Operation for menu item "$text" not implemented"""))
                  },
                  _.items <-- state.signal.map(_.theme).map { theme =>
                    import facades.vaadinMenuBar.mod.{MenuBarItem, SubMenuItem}
                    js.Array(
                      MenuBarItem().setText("File")
                        .setChildren(js.Array(
                          SubMenuItem().setText("Save")
                        )),
                      MenuBarItem().setText("Edit"),
                      MenuBarItem().setText("View")
                        .setChildren(js.Array(
                          SubMenuItem().setText("Theme")
                            .setChildren(js.Array(
                              SubMenuItem()
                                .setText("Dark")
                                .set("cmd", ToggleTheme(Some("dark")))
                                .setChecked(theme == "dark"),
                              SubMenuItem()
                                .setText("Light")
                                .set("cmd", ToggleTheme(Some("")))
                                .setChecked(theme == ""),
                            ))
                        )),
                      MenuBarItem().setText("Navigate"),
                      MenuBarItem().setText("Code"),
                    )
                  }
                ),
                _ => Button(
                  _ => cls := "self-end",
                  _ => theme := "primary contrast",
                  _ => span("Run!"),
                  _ => Icon(
                    _.icon := "vaadin:chevron-right",
                    _ => slot := "suffix"
                  ),
                  _ => onClick.map(_ => NotImplementedAlert("Compilation not implemented")) --> commandObserver,
                )

              ),
              _ => child <-- state.signal.map(_.testState != UITestState.HideEditor).map(showEditor => {
                if (showEditor) {
                  MonacoEditor(
                    state.signal.map(s => MonacoEditor.Props(s.theme)),
                    _ => width := "100%",
                    _ => flexGrow := "1",
                    _ => overflow := "hidden",
                  )
                } else {
                  emptyNode
                }
              }),
              _ => HorizontalLayout(
                _ => width := "100%",
                _ => padding := "var(--lumo-space-xs)",
                _ => fontSize := "var(--lumo-font-size-xxs)",
                _ => cls := "border-t border-contrast-10",
                _ => child <-- MonacoLanguageClientConnection.signal.map {
                  // bullseye
                  // circle
                  case MonacoLanguageClientConnection.State.Stopped =>
                    span(
                      color := "var(--lumo-error-color)",
                      Icon(
                        _.icon := "vaadin:circle",
                        _ => padding := "var(--lumo-space-xs)",
                        //                          _ => width := ".75rem",
                      ),
                      "Stopped"
                    )
                  case MonacoLanguageClientConnection.State.Starting =>
                    span(
                      color := "var(--lumo-primary-color)",
                      Icon(
                        _.icon := "vaadin:circle",
                        _ => padding := "var(--lumo-space-xs)",
                        //                          _ => width := ".75rem",
                      ),
                      "Starting"
                    )
                  case MonacoLanguageClientConnection.State.Running =>
                    span(
                      color := "var(--lumo-success-color)",
                      Icon(
                        _.icon := "vaadin:circle",
                        _ => padding := "var(--lumo-space-xs)",
                        //                          _ => width := ".75rem",
                      ),
                      "Running"
                    )
                }
              ),
            ),
          ),
          _ => div(
            height := "100%",
            minWidth := "200px",
            overflow := "visible",
            //              flex := s"1 1 ${state.now().chartWidth}px",
            flex <-- state.signal.map(_.chartWidth).map(w => s"1 1 ${w}px"),
            resizeObserver --> commandObserver.contramap { (entry: ResizeObserverEntry) =>
              // val parentWidth = entry.target.asInstanceOf[dom.Element].parentNode.asInstanceOf[dom.HTMLElement].clientWidth
              // val ratio = BigDecimal(100 * selfWidth / parentWidth).setScale(2, BigDecimal.RoundingMode.HALF_UP)
              val selfWidth = entry.contentRect.width
              ResizeChart(width = Some(selfWidth))
            },
            SplitLayout(
              _.orientation := SplitLayout.Orientation.vertical,
              _ => height := "100%",
              _ => width := "100%",
              _ => overflow := "visible",
              _ => div(
                width := "100%",
                overflow := "visible",
                minHeight := "200px",
                flex <-- state.signal.map(_.chartHeight).map(w => s"1 1 ${w}px"),
                resizeObserver --> commandObserver.contramap { (entry: ResizeObserverEntry) =>
                  // val parentWidth = entry.target.asInstanceOf[dom.Element].parentNode.asInstanceOf[dom.HTMLElement].clientWidth
                  // val ratio = BigDecimal(100 * selfWidth / parentWidth).setScale(2, BigDecimal.RoundingMode.HALF_UP)
                  val selfHeight = entry.contentRect.height
                  ResizeChart(height = Some(selfHeight))
                },
                child <--
                  state.signal.map(_.testState).map({
                    case UITestState.ShowChartsJS =>
                      ChartsJS(
                        state.signal.map(s => ChartsJS.Props(s.theme)),
                        _ => width := "100%",
                        _ => height := "100%",
                      )

                    case UITestState.ShowHighcharts =>
                      Highcharts(
                        state.signal.map(s => Highcharts.Props(s.theme)),
                        _ => width := "100%",
                        _ => height := "100%",
                      )

                    case UITestState.ShowLightweightCharts =>
                      LightweightCharts(
                        state.signal.map(s => LightweightCharts.Props(s.theme)),
                        _ => width := "100%",
                        _ => height := "100%",
                      )

                    case _ =>
                      // case UITestState.ShowUPlot =>
                      UPlot(
                        state.signal.map(s => UPlot.Props(s.theme)),
                        _ => width := "100%",
                        _ => height := "100%",
                      )
                  }),

              ),
              _ => Xterm(
                state.signal.map(s => Xterm.Props(s.theme)),
                _ => flex <-- state.signal.map(_.terminalHeight).map(w => s"1 1 ${w}px"),
                _ => resizeObserver --> commandObserver.contramap { (entry: ResizeObserverEntry) =>
                  // val parentWidth = entry.target.asInstanceOf[dom.Element].parentNode.asInstanceOf[dom.HTMLElement].clientWidth
                  // val ratio = BigDecimal(100 * selfWidth / parentWidth).setScale(2, BigDecimal.RoundingMode.HALF_UP)
                  val selfHeight = entry.contentRect.height
                  ResizeTerminal(selfHeight)
                },
              )
            ),
          ),
        ),
      )
    )

    // In most other examples, containerNode will be set to this behind the scenes
    val containerNode = dom.document.querySelector("#container")

    render(containerNode, rootElement)

    import concurrent.ExecutionContext.Implicits.global
    import facades.paciolanRemoteModuleLoader.loadRemoteModuleMod.{createLoadRemoteModule, CreateLoadRemoteModuleOptions}
    import facades.paciolanRemoteModuleLoader.xmlHttpRequestFetcherMod.{default => XmlHttpRequestFetcher}
    import scala.scalajs.js.JSConverters._
    createLoadRemoteModule(
      CreateLoadRemoteModuleOptions()
        .setFetcher(XmlHttpRequestFetcher)
    )("/api/fe73cd61-760e-427e-a2d8-f60d2a468143").toFuture.onComplete {
      case Success(value) =>
        // js.Dynamic.global.console.log(value.asInstanceOf[js.Any])
        // js.special.debugger()
        import js.JSConverters._
        // both should work in this examaple, however using the facade should be the only way as we are dealing with plain JS loading!
        try {
          val indicatorUnsafe = value.asInstanceOf[js.Dynamic].selectDynamic("default")().asInstanceOf[testing.Indicator]
          println("indicatorUnsafe.getValue(1, 2): " + indicatorUnsafe.getValue(1, 2))
        } catch {
          case t: Throwable =>
            println("Error occurred while trying to evaluate unsafe indicator")
            t.printStackTrace()
        }

        try {
          val indicatorSafe = value.asInstanceOf[js.Dynamic].selectDynamic("default")().asInstanceOf[com.nikolastojiljkovic.scalajs.facades.Indicator]
          println("indicatorSafe.getValue(1, 2): " + indicatorSafe.getValue(1, 2))
        } catch {
          case t: Throwable =>
            println("Error occurred while trying to evaluate safe indicator")
            t.printStackTrace()
        }
      case Failure(exception) =>
        println(exception)
    }
  }
}
