package com.nikolastojiljkovic.algot.client.components

import com.nikolastojiljkovic.algot.client.stubs.*
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.uplot.anon.Width as Size
import facades.uplot.mod.{Axis, Options, Series, uPlot, Legend, Plugin, Hooks, AlignedData}
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*
import monocle.syntax.all._
import org.scalajs.dom
import org.scalajs.dom.HTMLElement
import scala.scalajs.js
import scala.scalajs.js.annotation.{JSExportAll, JSImport}

object UPlot extends Laminar {

  // type AlignedData = js.Array[js.Array[scala.scalajs.js.UndefOr[Double | Int]]]

  @JSImport("uplot", "default")
  @js.native
  def uplot(opts: Options, data: AlignedData, targ: HTMLElement): uPlot = js.native

  // Laminar reactive element

  import com.raquo.laminar.api.L.{*, given}
  import com.raquo.laminar.nodes.ReactiveHtmlElement

  type REF = dom.html.Element
  type El = ReactiveHtmlElement[REF]
  type ModFunction = UPlot.type => Mod[El]

  case class Props(theme: String)

  case class State(theme: String = "")

  val data: AlignedData = js.Array(
    // timestamps
    SampleData.aapl.map(_._1 / 1000),
    // close
    SampleData.aapl.map(_._4),
    // volume
    SampleData.aapl.map(_._6),
  )

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val ed = Var(Option.empty[uPlot])
    val state = Var(State())

    // https://jsfiddle.net/bfg745km/
    def axisStroke(c: uPlot, axisIdx: Double) = state.now().theme match {
      case "dark" =>
        "#FFFFFF"
      case _ =>
        "#000000"
    }

    def axisGridStroke(c: uPlot, axisIdx: Double) = state.now().theme match {
      case "dark" =>
        "#383838"
      case _ =>
        "#cdcdcd"
    }

    type InitPlugin = scala.scalajs.js.Function3[uPlot, Options, AlignedData, Unit]
    val init: InitPlugin = (u, _, _) => {
      val legendEl: dom.HTMLElement = u.root.querySelector(".u-legend").asInstanceOf[dom.HTMLElement]
      legendEl.classList.remove("u-inline")
      // className && legendEl.classList.add(className)
      legendEl.style.textAlign = "left"
      legendEl.style.pointerEvents = "none"
      legendEl.style.display = "none"
      legendEl.style.position = "absolute"
      legendEl.style.left = "0"
      legendEl.style.top = "0"
      legendEl.style.zIndex = "10"
      legendEl.style.boxShadow = "2px 2px 10px rgba(0,0,0,0.5)"
      legendEl.style.backgroundColor = "rgba(255, 249, 196, 0.92)"
      legendEl.style.color = "black"

      // hide series color markers
      legendEl.querySelectorAll(".u-marker").foreach(el => {
        el.asInstanceOf[dom.HTMLElement].style.display = "none"
      })

      val overEl = u.over;
      overEl.style.overflow = "visible";

      // move legend into plot bounds
      overEl.appendChild(legendEl)

      // show/hide tooltip on enter/exit
      overEl.addEventListener("mouseenter", e => {
        legendEl.style.display = null
      })
      overEl.addEventListener("mouseleave", e => {
        legendEl.style.display = "none"
      })

      // let tooltip exit plot
      overEl.style.overflow = "visible";
      ()
    }
    val plugin1 = Plugin(Hooks.ArraysOrFuncs()
      .setInit(init)
      .setSetCursor((u) => {
        val cursor = u.cursor;
        // @todo: do not query for legendEl on every call
        val legendEl: dom.HTMLElement = u.root.querySelector(".u-legend").asInstanceOf[dom.HTMLElement]
        legendEl.style.transform = "translate(" + (cursor.left.get - legendEl.clientWidth) + "px, " + (cursor.top.get - legendEl.clientHeight) + "px)";
      }))

    val modifiers = mods.map(_ (UPlot)) :+
      onMountCallback[El] { ctx =>
        val opts = Options(
          width = 1,
          height = 1,
          series = js.Array(
            Series(),
            Series()
              .setLabel("CLOSE")
              .setStroke("red")
              .setScale("$"),
            Series()
              .setLabel("VOLUME")
              .setStroke("blue")
              .setScaleUndefined
          ))
          .setId("chart1")
          .setPlugins(js.Array(
            // view-source:https://leeoniya.github.io/uPlot/demos/candlestick-ohlc.html
            plugin1
          ))
          .setClass("my-chart")
          .setLegend(
            Legend().setShow(true)
          )
          .setAxes(js.Array(
            Axis()
              .setStroke(axisStroke)
              .setGrid(
                Axis.Grid()
                  .setWidth(1.0)
                  .setStroke(axisGridStroke)
              ),
            Axis()
              .setShow(true)
              .setSide(Axis.Side.Right)
              .setLabel("Price")
              .setScale("$")
              .setValues((uPlot, splits: js.Array[Double], axisIdx: Double, foundSpace: Double, foundIncr: Double) => {
                splits.map(v => v + "$")
              })
              .setGrid(
                Axis.Grid()
                  .setWidth(1.0)
                  .setStroke(axisGridStroke)
              )
              .setStroke(axisStroke),
            Axis()
              .setShow(true)
              .setSide(Axis.Side.Left)
              .setLabel("Volume [M]")
              .setScaleUndefined
              .setValues((uPlot, splits: js.Array[Double], axisIdx: Double, foundSpace: Double, foundIncr: Double) => {
                splits.map(v => (v / 1000000).toString)
              })
              .setGrid(
                Axis.Grid()
                  .setShow(false)
              )
              .setStroke(axisStroke),
          ))
        val chart = uplot(opts, data, ctx.thisNode.ref)
        ed.set(Some(chart))

        given Owner = ctx.owner

        state.signal.addObserver(Observer { s =>
          chart.redraw(false)
        })

        props.map(_.theme).addObserver(Observer { theme =>
          state.update(_.focus(_.theme).modify(_ => theme))
        })
      } :+
      onUnmountCallback[El] { thisNode =>
        ed.now().foreach(_.destroy())
        ed.set(None)
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        ed.now().foreach(chart => {
          //          val legend: js.UndefOr[dom.Element] = chart.root.querySelector(".u-legend")
          //          legend.toOption match {
          //            case Some(l) =>
          //              chart.setSize(Size(entry.contentRect.height - l.clientHeight, entry.contentRect.width))
          //            case _ =>
          //              chart.setSize(Size(entry.contentRect.height - 80, entry.contentRect.width))
          //          }
          chart.setSize(Size(entry.contentRect.height, entry.contentRect.width))
        })
      }

    div(modifiers: _*)
  }
}
