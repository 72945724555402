package com.nikolastojiljkovic.algot.client.components

import com.nikolastojiljkovic.algot.client.components.UPlot.Observer
import com.nikolastojiljkovic.algot.client.components.monaco.*
import com.nikolastojiljkovic.algot.client.stubs.*
import com.nikolastojiljkovic.scalajs.facades
import com.nikolastojiljkovic.scalajs.facades.xterm.xtermStrings.theme
import com.raquo.laminar.Laminar
import com.raquo.laminar.nodes.ReactiveHtmlElement
import facades.xterm.mod.*
import facades.xtermAddonFit.mod.*
import facades.xtermAddonWebgl.mod.*
import facades.gytxXtermLocalEcho.mod.LocalEchoAddon
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*
import monocle.syntax.all.*
import org.scalajs.dom
import org.scalajs.dom.HTMLElement

import scala.collection.immutable.Queue
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.annotation.{JSExportAll, JSImport}
import concurrent.ExecutionContext.Implicits.global

object Xterm extends Laminar {
  type REF = dom.html.Element
  type El = ReactiveHtmlElement[REF]
  type ModFunction = Xterm.type => Mod[El]

  private val darkTheme = ITheme()
    .setForeground("#d4d4d4")
    .setBackground("#1e1e1e")
    .setSelection("#5DA5D533")
    .setBlack("#1E1E1D")
    .setBrightBlack("#262625")
    .setRed("#CE5C5C")
    .setBrightRed("#FF7272")
    .setGreen("#5BCC5B")
    .setBrightGreen("#72FF72")
    .setYellow("#CCCC5B")
    .setBrightYellow("#FFFF72")
    .setBlue("#5D5DD3")
    .setBrightBlue("#7279FF")
    .setMagenta("#BC5ED1")
    .setBrightMagenta("#E572FF")
    .setCyan("#5DA5D5")
    .setBrightCyan("#72F0FF")
    .setWhite("#d4d4d4")
    .setBrightWhite("#FFFFFF")
    .setCursor("#d4d4d4")
    .setCursorAccent("#FFFFFF")

  private val lightTheme = ITheme()
    .setForeground("#000000")
    .setBackground("#fffffe")
    .setSelection("#5DA5D533")
    .setBlack("#d4d4d4")
    .setBrightBlack("#FFFFFF")
    .setRed("#CE5C5C")
    .setBrightRed("#FF7272")
    .setGreen("#5BCC5B")
    .setBrightGreen("#72FF72")
    .setYellow("#CCCC5B")
    .setBrightYellow("#FFFF72")
    .setBlue("#5D5DD3")
    .setBrightBlue("#7279FF")
    .setMagenta("#BC5ED1")
    .setBrightMagenta("#E572FF")
    .setCyan("#5DA5D5")
    .setBrightCyan("#72F0FF")
    .setWhite("#1E1E1D")
    .setBrightWhite("#262625")
    .setCursor("#1E1E1D")
    .setCursorAccent("#262625")

  case class Props(theme: String)
  case class State(terminal: Terminal, monacoTerminal: MonacoTerminal, fitAddon: FitAddon, localEchoAddon: LocalEchoAddon, allAddons: Seq[ITerminalAddon with IDisposable])

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val ed: Var[Option[State]] = Var(Option.empty[State])
    val (resizeStream, resizeCallback) = EventStream.withCallback[ResizeObserverEntry]
    def checkIfMounted = if (ed.now().isEmpty) {
      Future.failed(new RuntimeException("Terminal unmounted"))
    } else {
      Future.successful("terminal mounted")
    }
    val modifiers = mods.map(_ (Xterm)) :+
      onMountCallback[El] { ctx =>
        given Owner = ctx.owner
        val monacoTerminal = new MonacoTerminal(MonacoLanguageClientConnection.languageClientSignal)

        val term = new Terminal(ITerminalOptions()
          .setCursorBlink(true)
          .setFontFamily("""Menlo, Monaco, "Courier New", monospace""")
          .setFontSize(12)
          .setTheme(lightTheme)
        )
        val fitAddon = new FitAddon()
        term.loadAddon(fitAddon)
        term.open(ctx.thisNode.ref)
        fitAddon.fit()

        props.map(_.theme).addObserver(Observer {
          case "dark" =>
            term.setOption_theme(theme, darkTheme)
          case _ =>
            term.setOption_theme(theme, lightTheme)
        })

        // @todo: implement help for commands
        // @todo: scroll down on initial command with long log
        // @todo: implement echo $?

        term.writeln("\u001b[1mALGOT terminal, developed with \u2764\ufe0f\u001b[0m")

        val localEcho = new LocalEchoAddon
        term.loadAddon(localEcho)
        val environment = Some(js.Dynamic.literal("sampleInt" -> 123, "sampleString" -> "123"))
        val prompt = "~$ "

        try {
          val webglAddon = new WebglAddon()
          // suboptimal way of handling context loss
          webglAddon.onContextLoss((_, _) => {
            webglAddon.dispose()
          });
          term.loadAddon(webglAddon)
          ed.set(Some(State(term, monacoTerminal, fitAddon, localEcho, Seq(fitAddon, webglAddon, localEcho))))
        } catch {
          case t: Throwable =>
            ed.set(Some(State(term, monacoTerminal, fitAddon, localEcho, Seq(fitAddon, localEcho))))
            t.printStackTrace()
        }

        MonacoLanguageClientConnection.terminalMessageStream.foreach(terminalMessage => {
          if (terminalMessage.terminalId == monacoTerminal.id) {
            if (terminalMessage.isError) {
              term.write("\u001b[31;1m")
              term.write(terminalMessage.message)
              term.writeln("\u001b[0m")
            } else {
              term.writeln(terminalMessage.message)
            }
          }
        })

        // @see https://xtermjs.org/js/demo.js
        class BusyTerminalAddon(terminal: Terminal, localEcho: LocalEchoAddon, monacoTerminal: MonacoTerminal) {
          type R = (Queue[String], String)
          private var promise: Promise[R] = Promise()
          private var active = false
          private var queue = Queue[String]()
          private var command = ""

          terminal.onData((str, d) =>
            str match {
              case "\u0003" =>
                monacoTerminal.cancelCurrentRequest
                if (active) {
                  term.write("^C")
                }
              case "\r" if active => // enter
                queue = queue :+ command
                if (command.trim.nonEmpty) {
                  localEcho.history.push(command)
                  command = ""
                }
              case "\u007F" if active => // Backspace (DEL)
                val x = term.asInstanceOf[js.Dynamic].selectDynamic("_core").selectDynamic("buffer").selectDynamic("x").asInstanceOf[Int]
                if (x > 2) {
                  term.write("\b \b")
                  if (command.trim.nonEmpty) {
                    command = command.substring(0, command.length - 1);
                  }
                }
              case e if active =>
                val chr: Char = e.toCharArray.head
                val chrCode = chr.toInt

                if (chrCode >= 0x20 && chrCode <= 0x7B || chrCode >= 0xA0) {
                  command += e
                  term.write(e)
                }
              case _ =>
            }
          )

          def start(pendingCommand: String): BusyTerminalAddon = {
            if (!active) {
              active = true
              queue = Queue[String]()
              command = pendingCommand
              promise = Promise()
            }
            this
          }

          def stop(): BusyTerminalAddon = {
            if (active) {
              active = false
              promise.success((queue, command))
            }
            this
          }

          def future: Future[R] = {
            promise.future
          }
        }

        val busyTerminalAddon = new BusyTerminalAddon(term, localEcho, monacoTerminal)

        def readLoop(commandQueue: Queue[String], partialCommand: String): Future[Unit] = {
          for {
            (currentQueue, currentPartialCommand) <- if (commandQueue.isEmpty) {
              val f = localEcho.read(prompt).toFuture.map {
                case command if command.trim.nonEmpty =>
                  // no partial command, we've just received ENTER
                  (Queue(command), "")
                case _ =>
                  (Queue(), "")
              }
              import scala.scalajs.js.timers._
              setTimeout(1) { // note the absence of () =>
                // work
                if (partialCommand.nonEmpty) {
                  try {
                    // NOTE: Dynamic!
                    val coreService = term.asInstanceOf[js.Dynamic]._core.coreService
                    coreService.triggerDataEvent(partialCommand, true)
                  } catch {
                    case t: Throwable =>
                      t.printStackTrace()
                  }
                }
              }
              f
            } else {
              // terminal is busy, pass the current partial command
              Future.successful((commandQueue, partialCommand))
            }
            _ <- checkIfMounted
            (newQueue, newPartialCommand) <- {
              currentQueue.dequeueOption match {
                case Some((command, newQueue)) =>
                  val executePromise = Promise[Unit]()
                  // trick to reset localEcho internal this.input so that running command is not displayed on terminal resize
                  localEcho.read("")
                  localEcho.abortRead()
                  // execute command!
                  monacoTerminal.sendRequest(TerminalCommand[Int](command, environment, r => {
                    // @todo: save return value
                    executePromise.success(())
                  }, t => {
                    term.write("\u001b[31;1m")
                    term.write(t.getMessage)
                    term.writeln("\u001b[0m")
                    executePromise.success(())
                  }))
                  busyTerminalAddon.start(currentPartialCommand)
                  val r = for {
                    v1 <- executePromise.future
                    (pendingQueue, pendingCommand) <- busyTerminalAddon.stop().future
                  } yield (newQueue ++ pendingQueue, pendingCommand)
                  r
                case _ =>
                  Future.successful((currentQueue, ""))
              }
            }
            _ <- checkIfMounted
            _ <- readLoop(newQueue.filter(_.nonEmpty), newPartialCommand)
          } yield ()
        }

        // Infinite loop of reading lines until the terminal is unmounted
        readLoop(Queue(), "")
      } :+
      onUnmountCallback[El] { thisNode =>
        val oldState = ed.now()
        ed.set(None)
        oldState.foreach(s => {
          s.monacoTerminal.cancelCurrentRequest
          s.localEchoAddon.abortRead()
          s.allAddons.foreach(_.dispose())
          s.terminal.dispose()
        })
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        resizeCallback(entry)
      } :+
      // throttle is the key to make flicker free resize of Xterm
      resizeStream.throttle(50) --> { (entry: ResizeObserverEntry) =>
        ed.now().foreach(_.fitAddon.fit())
        // ed.now().foreach(s => {
        //   val term = s._1
        //   val cellWidth = term.asInstanceOf[js.Dynamic].selectDynamic("_core").selectDynamic("_renderService").selectDynamic("_charSizeService").selectDynamic("width").asInstanceOf[Int]
        //   val cellHeight = term.asInstanceOf[js.Dynamic].selectDynamic("_core").selectDynamic("_renderService").selectDynamic("_charSizeService").selectDynamic("height").asInstanceOf[Int]
        //   val cols = Math.floor(entry.contentRect.width / cellWidth)
        //   val rows = Math.floor(entry.contentRect.height / cellHeight)
        //   term.resize(cols, rows)
        // })
      }

    div(modifiers: _*)
  }
}
