package com.nikolastojiljkovic.algot.client.components.monaco

import com.nikolastojiljkovic.scalajs.facades
import com.nikolastojiljkovic.scalajs.facades.ScalaLanguage
import com.raquo.airstream.ownership.Owner
import com.raquo.domtypes.generic.codecs.StringAsIsCodec
import com.raquo.laminar.Laminar
import facades.codingameMonacoLanguageclient.connectionMod.{ConnectionCloseHandler, ConnectionErrorHandler, IConnection, IConnectionProvider}
import facades.vscodeLanguageclient.clientMod.{CloseAction, ErrorAction, ErrorHandler, LanguageClientOptions, StateChangeEvent}
import facades.codingameMonacoLanguageclient.mod.MonacoLanguageClient
import facades.codingameMonacoLanguageclient.monacoLanguageClientMod.MonacoLanguageClient.Options as MonacoLanguageClientOptions
import facades.monacoEditorCore.mod.languages.{ILanguageExtensionPoint, IMonarchLanguage, LanguageConfiguration}
import facades.vscodeJsonrpc.connectionMod.MessageConnection
import facades.codingameMonacoLanguageclient.monacoServicesMod.MonacoServices
import facades.codingameMonacoLanguageclient.anon.Typeofmonaco
import facades.monacoEditorCore.mod as monaco
import facades.monacoEditorCore.monacoEditorCoreRequire as monacoExports
import facades.monacoEditorCore.monacoEditorCoreStrings.*
import facades.monacoEditorCore.mod.editor.*
import facades.normalizeUrl.mod.default as normalizeUrl
import facades.reconnectingWebsocket.mod.default as ReconnectingWebsocket
import facades.codingameMonacoJsonrpc.mod.toSocket
import facades.codingameMonacoJsonrpc.mod.createWebSocketConnection
import facades.vscodeJsonrpc.connectionMod.Logger
import facades.codingameMonacoJsonrpc.loggerMod.ConsoleLogger
import facades.vscodeJsonrpc.disposableMod.Disposable
import io.laminext.domext.ResizeObserverEntry
import japgolly.scalajs.react.Callback
import org.scalajs.dom
import org.scalajs.dom.WebSocket

import scala.scalajs.js
import scala.scalajs.js.Promise
import scala.scalajs.js.annotation.JSGlobal
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*

object MonacoEditor extends Laminar {

  MonacoLanguageClientConnection.signal

  type Editor = IStandaloneCodeEditor

  def apply(el: dom.HTMLElement): Editor = {
    val sampleCode =
      """
        |import dsl.*
        |
        |object Sample {
        |  val sampleIndicator = indicator {
        |
        |    println("start of indicator evaluation")
        |    val delta = input("delta", 2)
        |
        |    println("plotting close")
        |    plot(close, "close")
        |
        |    println("evaluating oc2")
        |    val oc2 = (open + close) / 2
        |    if (open > close) {
        |      println("open is bigger than close")
        |    }
        |
        |    val oc2i = (open + close(1)) / 2
        |    println("plotting oc2")
        |    plot(oc2, "oc2")
        |
        |    val t = open /% 4
        |    val (div, rem) = t
        |
        |    println("plotting oc2 + delta")
        |    plot(oc2 + delta, "oc2 + delta")
        |
        |    println("done")
        |  }
        |}
        |
        |object Main:
        |  def main(args: Array[String]): Unit =
        |    Sample.sampleIndicator
        |    println("Hell yeah!!!")
        |""".stripMargin.trim

    val uri = monaco.Uri.parse("Main.scala")
    import com.nikolastojiljkovic.scalajs.facades.monacoEditorCore.mod.editor.ITextModel
    val model: ITextModel = Option(monaco.editor.getModel(uri)).getOrElse(
      monaco.editor.createModel(sampleCode, "scala", uri)
    )
    model.onDidChangeContent(evt => {
      // @todo: use this to sync with the state?
      // dom.console.log("Model changed content: ", model.getVersionId(), model.getValue())
    })
//    monaco.editor.onDidChangeModelLanguage(model => {
//      dom.console.log("Model changed: ", model)
//    })

    val editor: IStandaloneCodeEditor with ICodeEditor with IEditor = monaco.editor.create(
      el,
      //      new IStandaloneEditorConstructionOptions {
      //        model = monaco.editor.createModel(sampleCode, "scala", monaco.Uri.parse("Main.scala"))
      //        theme = "vs-dark"
      //        glyphMargin = true
      //      }
      IStandaloneEditorConstructionOptions()
        .setModel(model)
        // .setTheme(props.theme)
        .setTabCompletion(on)
        .setGlyphMargin(true)
        .setLightbulb(
          IEditorLightbulbOptions().setEnabled(true)
        )
        .setMinimap(
          IEditorMinimapOptions().setEnabled(false)
        )
        // .setFixedOverflowWidgets(true)
        //.setOverflowWidgetsDomNode(dom.document.body)
    )
    // https://stackoverflow.com/questions/48210120/get-restore-monaco-editor-undoredo-stack
    // @todo: stream view state!
    // editor.saveViewState

    // custom edit as a undo/redo change/step
    // https://stackoverflow.com/questions/60965171/not-able-to-do-undo-in-monaco-editor

    editor.addCommand(monaco.KeyCode.F10, t => {
      println("F10 command!")
      dom.console.log(t)
      val model = editor.getModel().asInstanceOf[ITextModel]
      js.special.debugger()
      editor.trigger("whateer", "undo", js.undefined)
      js.Dynamic.global.console.log(t.asInstanceOf[js.Any])
    })
    editor.onDidChangeCursorSelection {
      (cursor: ICursorSelectionChangedEvent) =>
        ()
    }
    println("Finished initialization")
    editor
  }

  // Laminar reactive element

  import com.raquo.laminar.nodes.ReactiveHtmlElement

  type REF = dom.html.Element
  type El = ReactiveHtmlElement[REF]
  type ModFunction = MonacoEditor.type => Mod[El]

  case class Props(theme: String)

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val editorVar = Var(Option.empty[Editor])

    val modifiers = mods.map(_ (MonacoEditor)) :+
      onMountCallback[El] { ctx =>
        // @todo: pass current props to apply method
        // @todo: AppState <-> MonacoEditor.Props transformations
        val editor = MonacoEditor(ctx.thisNode.ref)
        editorVar.set(Some(editor))
        given Owner = ctx.owner
        props.map(_.theme).addObserver(Observer { theme =>
          val vsTheme = theme match {
            case "dark" => "vs-dark"
            case _ => ""
          }
          editor.updateOptions(
            IStandaloneEditorConstructionOptions()
              .setTheme(vsTheme)
          )
        })
      } :+
      onUnmountCallback[El] { thisNode =>
        println("Editor unmounted, disposing...")
        editorVar.now().foreach(_.dispose())
        editorVar.set(None)
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        editorVar.now().foreach(_.layout())
      }

    div(modifiers: _*)
  }
}
