package com.nikolastojiljkovic.algot.client.components.monaco

import com.nikolastojiljkovic.algot.client.airstream.QueuedEventStream
import com.nikolastojiljkovic.algot.client.components.monaco.MonacoLanguageClientConnection.State
import com.nikolastojiljkovic.scalajs.facades
import com.nikolastojiljkovic.scalajs.facades.codingameMonacoLanguageclient.mod.MonacoLanguageClient
import com.nikolastojiljkovic.scalajs.facades.vscodeJsonrpc.cancellationMod
import com.nikolastojiljkovic.scalajs.facades.vscodeJsonrpc.cancellationMod.CancellationTokenSource
import com.raquo.airstream.core.{EventStream, Signal}
import com.raquo.airstream.state.{StrictSignal, Var}
import com.raquo.airstream.ownership.Owner
import org.scalajs.dom

import java.util.UUID
import scala.scalajs.js
import scala.util.{Failure, Success}
import scala.reflect.ClassTag
import scala.concurrent.ExecutionContext.Implicits.global
import scala.concurrent.Future
import scala.scalajs.js.Promise
import scala.scalajs.js.Promise.reject
import scala.scalajs.js.annotation.JSExportAll

class MonacoTerminal
(
  languageClient: Signal[Option[MonacoLanguageClient]]
)(using owner: Owner) {
  val id: String = UUID.randomUUID().toString
  case class CommandWithCancellationToken(command: TerminalCommand[_], token: CancellationTokenSource)

  private val (stream, callback) = EventStream.withCallback[CommandWithCancellationToken]
  private val currentCancellationToken: Var[Option[CancellationTokenSource]] = Var(None)
  private val _available: Var[Boolean] = Var(true)
  val available: StrictSignal[Boolean] = _available.signal
  private val streamWithAvailability = new QueuedEventStream[CommandWithCancellationToken](
    stream,
    available
  )

  streamWithAvailability.withCurrentValueOf(languageClient).foreach(t => {
    val client = t._2
    val command: TerminalCommand[_] = t._1.command
    val cancellationToken = t._1.token
    if (!cancellationToken.token.isCancellationRequested) {
      client match {
        case Some(languageClient: MonacoLanguageClient) =>
          Var.set(currentCancellationToken -> Some(cancellationToken), _available -> false)
          val future = try {
            command.parameter match {
              case Some(params) =>
                val req = js.Dynamic.literal(
                  "terminalId" -> id,
                  "method" -> command.method,
                  "parameter" -> params
                )
                languageClient.sendRequest[command.RESPONSE]("algot/terminal", req, cancellationToken.token).toFuture
              case _ =>
                val req = js.Dynamic.literal(
                  "terminalId" -> id,
                  "method" -> command.method,
                  "parameter" -> null
                )
                languageClient.sendRequest[command.RESPONSE]("algot/terminal", req, cancellationToken.token).toFuture
            }
          } catch {
            case t: Throwable =>
              t.printStackTrace()
              Future.failed(t)
          }
          future.onComplete {
            case Success(value: command.RESPONSE) =>
              command.onSuccess(value)
              Var.set(currentCancellationToken -> None, _available -> true)
              cancellationToken.dispose()
            case Failure(exception) =>
              command.onError(exception)
              Var.set(currentCancellationToken -> None, _available -> true)
              cancellationToken.dispose()
          }
        case _ =>
          command.onError(new RuntimeException("No language client present"))
          Var.set(currentCancellationToken -> None, _available -> true)
          cancellationToken.dispose()
      }
    }
  })

  def cancelCurrentRequest: Boolean = {
    currentCancellationToken.now() match {
      case Some(token) =>
        token.cancel()
        true
      case _ =>
        false
    }
  }

  def busy: Boolean = !_available.now()

  def sendRequest[R](command: TerminalCommand[R]): cancellationMod.CancellationToken = {
    val token = new CancellationTokenSource
    callback(CommandWithCancellationToken(command, token))
    token.token
  }
}

