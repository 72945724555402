package com.nikolastojiljkovic.algot.client.components.monaco

import scala.reflect.ClassTag
import scala.scalajs.js

case class TerminalCommand[R: ClassTag]
(
  method: String,
  parameter: Option[js.Object] = None,
  onSuccess: R => Unit = (r: R) => (),
  onError: Throwable => Unit = (t: Throwable) => ()
)(using val classTag: ClassTag[R]) {
  type RESPONSE = R
}