package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.{AsIsCodec, StringAsIsCodec, Codec as LaminarCodec}
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinMenuBar.vaadinMenuBarMod.MenuBar as VaadinMenuBar
import io.circe.Codec

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object MenuBar extends Laminar {

  facades.vaadinMenuBar.vaadinMenuBarRequire

  type REF = dom.html.Element with VaadinMenuBar
  type El = ReactiveHtmlElement[REF]
  type ModFunction = MenuBar.type => Mod[El]

  private val tag = customHtmlTag[REF]("vaadin-menu-bar")

  val theme: HtmlAttr[String] = customHtmlAttr("theme", StringAsIsCodec)

  import facades.vaadinMenuBar.mod.MenuBarItem
  val items: Prop[js.Array[MenuBarItem]] = customProp("items", AsIsCodec[js.Array[MenuBarItem]])

  val onItemSelected: EventProp[dom.CustomEvent] = customEventProp("item-selected")

  def apply(mods: ModFunction*): El = {
    val modifiers = mods.map(_ (MenuBar)) //:+ onItemSelected --> itemSelectedObserver
    tag(
      modifiers: _*
    )
  }
}