package com.nikolastojiljkovic.algot.client.stubs

import scala.scalajs.js

object SampleData {
  lazy val aapl: js.Array[js.Tuple6[Double, Double, Double, Double, Double, Double]] = js.Array(
    js.Tuple6(1580826600000.0,78.83,79.91,78.41,79.71,136616400),
    js.Tuple6(1580913000000.0,80.88,81.19,79.74,80.36,118826800),
    js.Tuple6(1580999400000.0,80.64,81.31,80.07,81.3,105425600),
    js.Tuple6(1581085800000.0,80.59,80.85,79.5,80.01,117684000),
    js.Tuple6(1581345000000.0,78.54,80.39,78.46,80.39,109348800),
    js.Tuple6(1581431400000.0,80.9,80.97,79.68,79.9,94323200),
    js.Tuple6(1581517800000.0,80.37,81.81,80.37,81.8,113730400),
    js.Tuple6(1581604200000.0,81.05,81.56,80.84,81.22,94747600),
    js.Tuple6(1581690600000.0,81.18,81.5,80.71,81.24,80113600),
    js.Tuple6(1582036200000.0,78.84,79.94,78.65,79.75,152531200),
    js.Tuple6(1582122600000.0,80,81.14,80,80.9,93984000),
    js.Tuple6(1582209000000.0,80.66,81.16,79.55,80.07,100566000),
    js.Tuple6(1582295400000.0,79.65,80.11,77.63,78.26,129554000),
    js.Tuple6(1582554600000.0,74.32,76.04,72.31,74.54,222195200),
    js.Tuple6(1582641000000.0,75.24,75.63,71.53,72.02,230673600),
    js.Tuple6(1582727400000.0,71.63,74.47,71.63,73.16,198054800),
    js.Tuple6(1582813800000.0,70.28,71.5,68.24,68.38,320605600),
    js.Tuple6(1582900200000.0,64.32,69.6,64.09,68.34,426510000),
    js.Tuple6(1583159400000.0,70.57,75.36,69.43,74.7,341397200),
    js.Tuple6(1583245800000.0,75.92,76,71.45,72.33,319475600),
    js.Tuple6(1583332200000.0,74.11,75.85,73.28,75.68,219178400),
    js.Tuple6(1583418600000.0,73.88,74.89,72.85,73.23,187572800),
    js.Tuple6(1583505000000.0,70.5,72.71,70.31,72.26,226176800),
    js.Tuple6(1583760600000.0,65.94,69.52,65.75,66.54,286744800),
    js.Tuple6(1583847000000.0,69.29,71.61,67.34,71.33,285290000),
    js.Tuple6(1583933400000.0,69.35,70.31,67.96,68.86,255598800),
    js.Tuple6(1584019800000.0,63.99,67.5,62,62.06,418474000),
    js.Tuple6(1584106200000.0,66.22,69.98,63.24,69.49,370732000),
    js.Tuple6(1584365400000.0,60.49,64.77,60,60.55,322423600),
    js.Tuple6(1584451800000.0,61.88,64.4,59.6,63.22,324056000),
    js.Tuple6(1584538200000.0,59.94,62.5,59.28,61.67,300233600),
    js.Tuple6(1584624600000.0,61.85,63.21,60.65,61.19,271857200),
    js.Tuple6(1584711000000.0,61.79,62.96,57,57.31,401693200),
    js.Tuple6(1584970200000.0,57.02,57.13,53.15,56.09,336752800),
    js.Tuple6(1585056600000.0,59.09,61.92,58.58,61.72,287531200),
    js.Tuple6(1585143000000.0,62.69,64.56,61.08,61.38,303602000),
    js.Tuple6(1585229400000.0,61.63,64.67,61.59,64.61,252087200),
    js.Tuple6(1585315800000.0,63.19,63.97,61.76,61.94,204216800),
    js.Tuple6(1585575000000.0,62.69,63.88,62.35,63.7,167976400),
    js.Tuple6(1585661400000.0,63.9,65.62,63,63.57,197002000),
    js.Tuple6(1585747800000.0,61.63,62.18,59.78,60.23,176218400),
    js.Tuple6(1585834200000.0,60.08,61.29,59.22,61.23,165934000),
    js.Tuple6(1585920600000.0,60.7,61.42,59.74,60.35,129880000),
    js.Tuple6(1586179800000.0,62.72,65.78,62.35,65.62,201820400),
    js.Tuple6(1586266200000.0,67.7,67.93,64.75,64.86,202887200),
    js.Tuple6(1586352600000.0,65.68,66.84,65.31,66.52,168895200),
    js.Tuple6(1586439000000.0,67.18,67.52,66.18,67,161834800),
    js.Tuple6(1586784600000.0,67.08,68.43,66.46,68.31,131022800),
    js.Tuple6(1586871000000.0,70,72.06,69.51,71.76,194994800),
    js.Tuple6(1586957400000.0,70.6,71.58,70.16,71.11,131154400),
    js.Tuple6(1587043800000.0,71.85,72.05,70.59,71.67,157125200),
    js.Tuple6(1587130200000.0,71.17,71.74,69.21,70.7,215250000),
    js.Tuple6(1587389400000.0,69.49,70.42,69.21,69.23,130015200),
    js.Tuple6(1587475800000.0,69.07,69.31,66.36,67.09,180991600),
    js.Tuple6(1587562200000.0,68.4,69.47,68.05,69.03,116862400),
    js.Tuple6(1587648600000.0,68.97,70.44,68.72,68.76,124814400),
    js.Tuple6(1587735000000.0,69.3,70.75,69.25,70.74,126161200),
    js.Tuple6(1587994200000.0,70.45,71.14,69.99,70.79,117087600),
    js.Tuple6(1588080600000.0,71.27,71.46,69.55,69.64,112004800),
    js.Tuple6(1588167000000.0,71.18,72.42,70.97,71.93,137280800),
    js.Tuple6(1588253400000.0,72.49,73.63,72.09,73.45,183064000),
    js.Tuple6(1588339800000.0,71.56,74.75,71.46,72.27,240616800),
    js.Tuple6(1588599000000.0,72.29,73.42,71.58,73.29,133568000),
    js.Tuple6(1588685400000.0,73.76,75.25,73.61,74.39,147751200),
    js.Tuple6(1588771800000.0,75.11,75.81,74.72,75.16,142333600),
    js.Tuple6(1588858200000.0,75.81,76.29,75.49,75.93,115215200),
    js.Tuple6(1588944600000.0,76.41,77.59,76.07,77.53,133838400),
    js.Tuple6(1589203800000.0,77.03,79.26,76.81,78.75,145946400),
    js.Tuple6(1589290200000.0,79.46,79.92,77.73,77.85,162301200),
    js.Tuple6(1589376600000.0,78.04,78.99,75.8,76.91,200622400),
    js.Tuple6(1589463000000.0,76.13,77.45,75.38,77.39,158929200),
    js.Tuple6(1589549400000.0,75.09,76.97,75.05,76.93,166348400),
    js.Tuple6(1589808600000.0,78.29,79.13,77.58,78.74,135178400),
    js.Tuple6(1589895000000.0,78.76,79.63,78.25,78.29,101729600),
    js.Tuple6(1589981400000.0,79.17,79.88,79.13,79.81,111504800),
    js.Tuple6(1590067800000.0,79.67,80.22,78.97,79.21,102688800),
    js.Tuple6(1590154200000.0,78.94,79.81,78.84,79.72,81803200),
    js.Tuple6(1590499800000.0,80.88,81.06,79.13,79.18,125522000),
    js.Tuple6(1590586200000.0,79.04,79.68,78.27,79.53,112945200),
    js.Tuple6(1590672600000.0,79.19,80.86,78.91,79.56,133560800),
    js.Tuple6(1590759000000.0,79.81,80.29,79.12,79.49,153532400),
    js.Tuple6(1591018200000.0,79.44,80.59,79.3,80.46,80791200),
    js.Tuple6(1591104600000.0,80.19,80.86,79.73,80.83,87642800),
    js.Tuple6(1591191000000.0,81.17,81.55,80.57,81.28,104491200),
    js.Tuple6(1591277400000.0,81.1,81.4,80.19,80.58,87560400),
    js.Tuple6(1591363800000.0,80.84,82.94,80.81,82.88,137250400),
    js.Tuple6(1591623000000.0,82.56,83.4,81.83,83.36,95654400),
    js.Tuple6(1591709400000.0,83.04,86.4,83,86,147712400),
    js.Tuple6(1591795800000.0,86.97,88.69,86.52,88.21,166651600),
    js.Tuple6(1591882200000.0,87.33,87.76,83.87,83.97,201662400),
    js.Tuple6(1591968600000.0,86.18,86.95,83.56,84.7,200146000),
    js.Tuple6(1592227800000.0,83.31,86.42,83.14,85.75,138808800),
    js.Tuple6(1592314200000.0,87.86,88.3,86.18,88.02,165428800),
    js.Tuple6(1592400600000.0,88.79,88.85,87.77,87.9,114406400),
    js.Tuple6(1592487000000.0,87.85,88.36,87.31,87.93,96820400),
    js.Tuple6(1592573400000.0,88.66,89.14,86.29,87.43,264476000),
    js.Tuple6(1592832600000.0,87.83,89.86,87.79,89.72,135445200),
    js.Tuple6(1592919000000.0,91,93.1,90.57,91.63,212155600),
    js.Tuple6(1593005400000.0,91.25,92.2,89.63,90.01,192623200),
    js.Tuple6(1593091800000.0,90.18,91.25,89.39,91.21,137522400),
    js.Tuple6(1593178200000.0,91.1,91.33,88.25,88.41,205256800),
    js.Tuple6(1593437400000.0,88.31,90.54,87.82,90.44,130646000),
    js.Tuple6(1593523800000.0,90.02,91.5,90,91.2,140223200),
    js.Tuple6(1593610200000.0,91.28,91.84,90.98,91.03,110737200),
    js.Tuple6(1593696600000.0,91.96,92.62,90.91,91.03,114041600),
    js.Tuple6(1594042200000.0,92.5,93.94,92.47,93.46,118655600),
    js.Tuple6(1594128600000.0,93.85,94.65,93.06,93.17,112424400),
    js.Tuple6(1594215000000.0,94.18,95.38,94.09,95.34,117092000),
    js.Tuple6(1594301400000.0,96.26,96.32,94.67,95.75,125642800),
    js.Tuple6(1594387800000.0,95.33,95.98,94.71,95.92,90257200),
    js.Tuple6(1594647000000.0,97.26,99.96,95.26,95.48,191649200),
    js.Tuple6(1594733400000.0,94.84,97.25,93.88,97.06,170989200),
    js.Tuple6(1594819800000.0,98.99,99.25,96.49,97.72,153198000),
    js.Tuple6(1594906200000.0,96.56,97.4,95.9,96.52,110577600),
    js.Tuple6(1594992600000.0,96.99,97.15,95.84,96.33,92186800),
    js.Tuple6(1595251800000.0,96.42,98.5,96.06,98.36,90318000),
    js.Tuple6(1595338200000.0,99.17,99.25,96.74,97,103433200),
    js.Tuple6(1595424600000.0,96.69,97.97,96.6,97.27,89001600),
    js.Tuple6(1595511000000.0,97,97.08,92.01,92.85,197004400),
    js.Tuple6(1595597400000.0,90.99,92.97,89.14,92.61,185438800),
    js.Tuple6(1595856600000.0,93.71,94.9,93.48,94.81,121214000),
    js.Tuple6(1595943000000.0,94.37,94.55,93.25,93.25,103625600),
    js.Tuple6(1596029400000.0,93.75,95.23,93.71,95.04,90329200),
    js.Tuple6(1596115800000.0,94.19,96.3,93.77,96.19,158130000),
    js.Tuple6(1596202200000.0,102.89,106.42,100.82,106.26,374336800),
    js.Tuple6(1596461400000.0,108.2,111.64,107.89,108.94,308151200),
    js.Tuple6(1596547800000.0,109.13,110.79,108.39,109.67,173071600),
    js.Tuple6(1596634200000.0,109.38,110.39,108.9,110.06,121776800),
    js.Tuple6(1596720600000.0,110.4,114.41,109.8,113.9,202428800),
    js.Tuple6(1596807000000.0,113.21,113.68,110.29,111.11,198045600),
    js.Tuple6(1597066200000.0,112.6,113.78,110,112.73,212403600),
    js.Tuple6(1597152600000.0,111.97,112.48,109.11,109.38,187902400),
    js.Tuple6(1597239000000.0,110.5,113.28,110.3,113.01,165598000),
    js.Tuple6(1597325400000.0,114.43,116.04,113.93,115.01,210082000),
    js.Tuple6(1597411800000.0,114.83,115,113.04,114.91,165565200),
    js.Tuple6(1597671000000.0,116.06,116.09,113.96,114.61,119561600),
    js.Tuple6(1597757400000.0,114.35,116,114.01,115.56,105633600),
    js.Tuple6(1597843800000.0,115.98,117.16,115.61,115.71,145538000),
    js.Tuple6(1597930200000.0,115.75,118.39,115.73,118.28,126907200),
    js.Tuple6(1598016600000.0,119.26,124.87,119.25,124.37,338054800),
    js.Tuple6(1598275800000.0,128.7,128.79,123.94,125.86,345937600),
    js.Tuple6(1598362200000.0,124.7,125.18,123.05,124.82,211495600),
    js.Tuple6(1598448600000.0,126.18,126.99,125.08,126.52,163022400),
    js.Tuple6(1598535000000.0,127.14,127.49,123.83,125.01,155552400),
    js.Tuple6(1598621400000.0,126.01,126.44,124.58,124.81,187630000),
    js.Tuple6(1598880600000.0,127.58,131,126,129.04,225702700),
    js.Tuple6(1598967000000.0,132.76,134.8,130.53,134.18,151948100),
    js.Tuple6(1599053400000.0,137.59,137.98,127,131.4,200119000),
    js.Tuple6(1599139800000.0,126.91,128.84,120.5,120.88,257599600),
    js.Tuple6(1599226200000.0,120.07,123.7,110.89,120.96,332607200),
    js.Tuple6(1599571800000.0,113.95,118.99,112.68,112.82,231366600),
    js.Tuple6(1599658200000.0,117.26,119.14,115.26,117.32,176940500),
    js.Tuple6(1599744600000.0,120.36,120.5,112.5,113.49,182274400),
    js.Tuple6(1599831000000.0,114.57,115.23,110,112,180860300),
    js.Tuple6(1600090200000.0,114.72,115.93,112.8,115.36,140150100),
    js.Tuple6(1600176600000.0,118.33,118.83,113.61,115.54,184642000),
    js.Tuple6(1600263000000.0,115.23,116,112.04,112.13,154679000),
    js.Tuple6(1600349400000.0,109.72,112.2,108.71,110.34,178011000),
    js.Tuple6(1600435800000.0,110.4,110.88,106.09,106.84,287104900),
    js.Tuple6(1600695000000.0,104.54,110.19,103.1,110.08,195713800),
    js.Tuple6(1600781400000.0,112.68,112.86,109.16,111.81,183055400),
    js.Tuple6(1600867800000.0,111.62,112.11,106.77,107.12,150718700),
    js.Tuple6(1600954200000.0,105.17,110.25,105,108.22,167743300),
    js.Tuple6(1601040600000.0,108.43,112.44,107.67,112.28,149981400),
    js.Tuple6(1601299800000.0,115.01,115.32,112.78,114.96,137672400),
    js.Tuple6(1601386200000.0,114.55,115.31,113.57,114.09,99382200),
    js.Tuple6(1601472600000.0,113.79,117.26,113.62,115.81,142675200),
    js.Tuple6(1601559000000.0,117.64,117.72,115.83,116.79,116120400),
    js.Tuple6(1601645400000.0,112.89,115.37,112.22,113.02,144712000),
    js.Tuple6(1601904600000.0,113.91,116.65,113.55,116.5,106243800),
    js.Tuple6(1601991000000.0,115.7,116.12,112.25,113.16,161498200),
    js.Tuple6(1602077400000.0,114.62,115.55,114.13,115.08,96849000),
    js.Tuple6(1602163800000.0,116.25,116.4,114.59,114.97,83477200),
    js.Tuple6(1602250200000.0,115.28,117,114.92,116.97,100506900),
    js.Tuple6(1602509400000.0,120.06,125.18,119.28,124.4,240226800),
    js.Tuple6(1602595800000.0,125.27,125.39,119.65,121.1,262330500),
    js.Tuple6(1602682200000.0,121,123.03,119.62,121.19,150712000),
    js.Tuple6(1602768600000.0,118.72,121.2,118.15,120.71,112559200),
    js.Tuple6(1602855000000.0,121.28,121.55,118.81,119.02,115393800),
    js.Tuple6(1603114200000.0,119.96,120.42,115.66,115.98,120639300),
    js.Tuple6(1603200600000.0,116.2,118.98,115.63,117.51,124423700),
    js.Tuple6(1603287000000.0,116.67,118.71,116.45,116.87,89946000),
    js.Tuple6(1603373400000.0,117.45,118.04,114.59,115.75,101988000),
    js.Tuple6(1603459800000.0,116.39,116.55,114.28,115.04,82572600),
    js.Tuple6(1603719000000.0,114.01,116.55,112.88,115.05,111850700),
    js.Tuple6(1603805400000.0,115.49,117.28,114.54,116.6,92276800),
    js.Tuple6(1603891800000.0,115.05,115.43,111.1,111.2,143937800),
    js.Tuple6(1603978200000.0,112.37,116.93,112.2,115.32,146129200),
    js.Tuple6(1604064600000.0,111.06,111.99,107.72,108.86,190272600),
    js.Tuple6(1604327400000.0,109.11,110.68,107.32,108.77,122866900),
    js.Tuple6(1604413800000.0,109.66,111.49,108.73,110.44,107624400),
    js.Tuple6(1604500200000.0,114.14,115.59,112.35,114.95,138235500),
    js.Tuple6(1604586600000.0,117.95,119.62,116.87,119.03,126387100),
    js.Tuple6(1604673000000.0,118.32,119.2,116.13,118.69,114457900),
    js.Tuple6(1604932200000.0,120.5,121.99,116.05,116.32,154515300),
    js.Tuple6(1605018600000.0,115.55,117.59,114.13,115.97,138023400),
    js.Tuple6(1605105000000.0,117.19,119.63,116.44,119.49,112295000),
    js.Tuple6(1605191400000.0,119.62,120.53,118.57,119.21,103162300),
    js.Tuple6(1605277800000.0,119.44,119.67,117.87,119.26,81581900),
    js.Tuple6(1605537000000.0,118.92,120.99,118.15,120.3,91183000),
    js.Tuple6(1605623400000.0,119.55,120.67,118.96,119.39,74271000),
    js.Tuple6(1605709800000.0,118.61,119.82,118,118.03,76322100),
    js.Tuple6(1605796200000.0,117.59,119.06,116.81,118.64,74113000),
    js.Tuple6(1605882600000.0,118.64,118.77,117.29,117.34,73604300),
    js.Tuple6(1606141800000.0,117.18,117.62,113.75,113.85,127959300),
    js.Tuple6(1606228200000.0,113.91,115.85,112.59,115.17,113874200),
    js.Tuple6(1606314600000.0,115.55,116.75,115.17,116.03,76499200),
    js.Tuple6(1606487400000.0,116.57,117.49,116.22,116.59,46691300),
    js.Tuple6(1606746600000.0,116.97,120.97,116.81,119.05,169410200),
    js.Tuple6(1606833000000.0,121.01,123.47,120.01,122.72,127728200),
    js.Tuple6(1606919400000.0,122.02,123.37,120.89,123.08,89004200),
    js.Tuple6(1607005800000.0,123.52,123.78,122.21,122.94,78967600),
    js.Tuple6(1607092200000.0,122.6,122.86,121.52,122.25,78260400),
    js.Tuple6(1607351400000.0,122.31,124.57,122.25,123.75,86712000),
    js.Tuple6(1607437800000.0,124.37,124.98,123.09,124.38,82225500),
    js.Tuple6(1607524200000.0,124.53,125.95,121,121.78,115089200),
    js.Tuple6(1607610600000.0,120.5,123.87,120.15,123.24,81312200),
    js.Tuple6(1607697000000.0,122.43,122.76,120.55,122.41,86939800),
    js.Tuple6(1607956200000.0,122.6,123.35,121.54,121.78,79184500),
    js.Tuple6(1608042600000.0,124.34,127.9,124.13,127.88,157243700),
    js.Tuple6(1608129000000.0,127.41,128.37,126.56,127.81,98208600),
    js.Tuple6(1608215400000.0,128.9,129.58,128.04,128.7,94359800),
    js.Tuple6(1608301800000.0,128.96,129.1,126.12,126.66,192541500),
    js.Tuple6(1608561000000.0,125.02,128.31,123.45,128.23,121251600),
    js.Tuple6(1608647400000.0,131.61,134.41,129.65,131.88,168904800),
    js.Tuple6(1608733800000.0,132.16,132.43,130.78,130.96,88223700),
    js.Tuple6(1608820200000.0,131.32,133.46,131.1,131.97,54930100),
    js.Tuple6(1609165800000.0,133.99,137.34,133.51,136.69,124486200),
    js.Tuple6(1609252200000.0,138.05,138.79,134.34,134.87,121047300),
    js.Tuple6(1609338600000.0,135.58,135.99,133.4,133.72,96452100),
    js.Tuple6(1609425000000.0,134.08,134.74,131.72,132.69,99116600),
    js.Tuple6(1609770600000.0,133.52,133.61,126.76,129.41,143301900),
    js.Tuple6(1609857000000.0,128.89,131.74,128.43,131.01,97664900),
    js.Tuple6(1609943400000.0,127.72,131.05,126.38,126.6,155088000),
    js.Tuple6(1610029800000.0,128.36,131.63,127.86,130.92,109578200),
    js.Tuple6(1610116200000.0,132.43,132.63,130.23,132.05,105158200),
    js.Tuple6(1610375400000.0,129.19,130.17,128.5,128.98,100384500),
    js.Tuple6(1610461800000.0,128.5,129.69,126.86,128.8,91951100),
    js.Tuple6(1610548200000.0,128.76,131.45,128.49,130.89,88636800),
    js.Tuple6(1610634600000.0,130.8,131,128.76,128.91,90221800),
    js.Tuple6(1610721000000.0,128.78,130.22,127,127.14,111598500),
    js.Tuple6(1611066600000.0,127.78,128.71,126.94,127.83,90757300),
    js.Tuple6(1611153000000.0,128.66,132.49,128.55,132.03,104319500),
    js.Tuple6(1611239400000.0,133.8,139.67,133.59,136.87,120150900),
    js.Tuple6(1611325800000.0,136.28,139.85,135.02,139.07,114459400),
    js.Tuple6(1611585000000.0,143.07,145.09,136.54,142.92,157611700),
    js.Tuple6(1611671400000.0,143.6,144.3,141.37,143.16,98390600),
    js.Tuple6(1611757800000.0,143.43,144.3,140.41,142.06,140843800),
    js.Tuple6(1611844200000.0,139.52,141.99,136.7,137.09,142621100),
    js.Tuple6(1611930600000.0,135.83,136.74,130.21,131.96,177523800),
    js.Tuple6(1612189800000.0,133.75,135.38,130.93,134.14,106239800),
    js.Tuple6(1612276200000.0,135.73,136.31,134.61,134.99,83305400),
    js.Tuple6(1612362600000.0,135.76,135.77,133.61,133.94,89880900),
    js.Tuple6(1612449000000.0,136.3,137.4,134.59,137.39,84183100),
    js.Tuple6(1612535400000.0,137.35,137.42,135.86,136.76,75693800),
    js.Tuple6(1612794600000.0,136.03,136.96,134.92,136.91,71297200),
    js.Tuple6(1612881000000.0,136.62,137.88,135.85,136.01,76774200),
    js.Tuple6(1612967400000.0,136.48,136.99,134.4,135.39,73046600),
    js.Tuple6(1613053800000.0,135.9,136.39,133.77,135.13,64280000),
    js.Tuple6(1613140200000.0,134.35,135.53,133.69,135.37,60145100),
    js.Tuple6(1613485800000.0,135.49,136.01,132.79,133.19,80576300),
    js.Tuple6(1613572200000.0,131.25,132.22,129.47,130.84,97918500),
    js.Tuple6(1613658600000.0,129.2,130,127.41,129.71,96856700),
    js.Tuple6(1613745000000.0,130.24,130.71,128.8,129.87,87668800),
    js.Tuple6(1614004200000.0,128.01,129.72,125.6,126,103916400),
    js.Tuple6(1614090600000.0,123.76,126.71,118.39,125.86,158273000),
    js.Tuple6(1614177000000.0,124.94,125.56,122.23,125.35,111039900),
    js.Tuple6(1614263400000.0,124.68,126.46,120.54,120.99,148199500),
    js.Tuple6(1614349800000.0,122.59,124.85,121.2,121.26,164560400),
    js.Tuple6(1614609000000.0,123.75,127.93,122.79,127.79,116307900),
    js.Tuple6(1614695400000.0,128.41,128.72,125.01,125.12,102260900),
    js.Tuple6(1614781800000.0,124.81,125.71,121.84,122.06,112966300),
    js.Tuple6(1614868200000.0,121.75,123.6,118.62,120.13,178155000),
    js.Tuple6(1614954600000.0,120.98,121.94,117.57,121.42,153766600),
    js.Tuple6(1615213800000.0,120.93,121,116.21,116.36,154376600),
    js.Tuple6(1615300200000.0,119.03,122.06,118.79,121.09,129525800),
    js.Tuple6(1615386600000.0,121.69,122.17,119.45,119.98,111943300),
    js.Tuple6(1615473000000.0,122.54,123.21,121.26,121.96,103026500),
    js.Tuple6(1615559400000.0,120.4,121.17,119.16,121.03,88105100),
    js.Tuple6(1615815000000.0,121.41,124,120.42,123.99,92403800),
    js.Tuple6(1615901400000.0,125.7,127.22,124.72,125.57,115227900),
    js.Tuple6(1615987800000.0,124.05,125.86,122.34,124.76,111932600),
    js.Tuple6(1616074200000.0,122.88,123.18,120.32,120.53,121229700),
    js.Tuple6(1616160600000.0,119.9,121.43,119.68,119.99,185549500),
    js.Tuple6(1616419800000.0,120.33,123.87,120.26,123.39,111912300),
    js.Tuple6(1616506200000.0,123.33,124.24,122.14,122.54,95467100),
    js.Tuple6(1616592600000.0,122.82,122.9,120.07,120.09,88530500),
    js.Tuple6(1616679000000.0,119.54,121.66,119,120.59,98844700),
    js.Tuple6(1616765400000.0,120.35,121.48,118.92,121.21,94071200),
    js.Tuple6(1617024600000.0,121.65,122.58,120.73,121.39,80819200),
    js.Tuple6(1617111000000.0,120.11,120.4,118.86,119.9,85671900),
    js.Tuple6(1617197400000.0,121.65,123.52,121.15,122.15,118323800),
    js.Tuple6(1617283800000.0,123.66,124.18,122.49,123,75089100),
    js.Tuple6(1617629400000.0,123.87,126.16,123.07,125.9,88651200),
    js.Tuple6(1617715800000.0,126.5,127.13,125.65,126.21,80171300),
    js.Tuple6(1617802200000.0,125.83,127.92,125.14,127.9,83466700),
    js.Tuple6(1617888600000.0,128.95,130.39,128.52,130.36,88844600),
    js.Tuple6(1617975000000.0,129.8,133.04,129.47,133,106686700),
    js.Tuple6(1618234200000.0,132.52,132.85,130.63,131.24,91420000),
    js.Tuple6(1618320600000.0,132.44,134.66,131.93,134.43,91266500),
    js.Tuple6(1618407000000.0,134.94,135,131.66,132.03,87222800),
    js.Tuple6(1618493400000.0,133.82,135,133.64,134.5,89347100),
    js.Tuple6(1618579800000.0,134.3,134.67,133.28,134.16,84922400),
    js.Tuple6(1618839000000.0,133.51,135.47,133.34,134.84,94264200),
    js.Tuple6(1618925400000.0,135.02,135.53,131.81,133.11,94812300),
    js.Tuple6(1619011800000.0,132.36,133.75,131.3,133.5,68847100),
    js.Tuple6(1619098200000.0,133.04,134.15,131.41,131.94,84566500),
    js.Tuple6(1619184600000.0,132.16,135.12,132.16,134.32,78657500),
    js.Tuple6(1619443800000.0,134.83,135.06,133.56,134.72,66905100),
    js.Tuple6(1619530200000.0,135.01,135.41,134.11,134.39,66015800),
    js.Tuple6(1619616600000.0,134.31,135.02,133.08,133.58,107760100),
    js.Tuple6(1619703000000.0,136.47,137.07,132.45,133.48,151101000),
    js.Tuple6(1619789400000.0,131.78,133.56,131.07,131.46,109839500),
    js.Tuple6(1620048600000.0,132.04,134.07,131.83,132.54,75135100),
    js.Tuple6(1620135000000.0,131.19,131.49,126.7,127.85,137564700),
    js.Tuple6(1620221400000.0,129.2,130.45,127.97,128.1,84000900),
    js.Tuple6(1620307800000.0,127.89,129.75,127.13,129.74,78128300),
    js.Tuple6(1620394200000.0,130.85,131.26,129.48,130.21,78973300),
    js.Tuple6(1620653400000.0,129.41,129.54,126.81,126.85,88071200),
    js.Tuple6(1620739800000.0,123.5,126.27,122.77,125.91,126142800),
    js.Tuple6(1620826200000.0,123.4,124.64,122.25,122.77,112172300),
    js.Tuple6(1620912600000.0,124.58,126.15,124.26,124.97,105861300),
    js.Tuple6(1620999000000.0,126.25,127.89,125.85,127.45,81918000),
    js.Tuple6(1621258200000.0,126.82,126.93,125.17,126.27,74244600),
    js.Tuple6(1621344600000.0,126.56,126.99,124.78,124.85,63342900),
    js.Tuple6(1621431000000.0,123.16,124.92,122.86,124.69,92612000),
    js.Tuple6(1621517400000.0,125.23,127.72,125.1,127.31,76857100),
    js.Tuple6(1621603800000.0,127.82,128,125.21,125.43,79295400),
    js.Tuple6(1621863000000.0,126.01,127.94,125.94,127.1,63092900),
    js.Tuple6(1621949400000.0,127.82,128.32,126.32,126.9,72009500),
    js.Tuple6(1622035800000.0,126.96,127.39,126.42,126.85,56575900),
    js.Tuple6(1622122200000.0,126.44,127.64,125.08,125.28,94625600),
    js.Tuple6(1622208600000.0,125.57,125.8,124.55,124.61,71311100),
    js.Tuple6(1622554200000.0,125.08,125.35,123.94,124.28,67637100),
    js.Tuple6(1622640600000.0,124.28,125.24,124.05,125.06,59278900),
    js.Tuple6(1622727000000.0,124.68,124.85,123.13,123.54,76229200),
    js.Tuple6(1622813400000.0,124.07,126.16,123.85,125.89,75169300),
    js.Tuple6(1623072600000.0,126.17,126.32,124.83,125.9,71057600),
    js.Tuple6(1623159000000.0,126.6,128.46,126.21,126.74,74403800),
    js.Tuple6(1623245400000.0,127.21,127.75,126.52,127.13,56877900),
    js.Tuple6(1623331800000.0,127.02,128.19,125.94,126.11,71186400),
    js.Tuple6(1623418200000.0,126.53,127.44,126.1,127.35,53522400),
    js.Tuple6(1623677400000.0,127.82,130.54,127.07,130.48,96906500),
    js.Tuple6(1623763800000.0,129.94,130.6,129.39,129.64,62746300),
    js.Tuple6(1623850200000.0,130.37,130.89,128.46,130.15,91815000),
    js.Tuple6(1623936600000.0,129.8,132.55,129.65,131.79,96721700),
    js.Tuple6(1624023000000.0,130.71,131.51,130.24,130.46,108953300),
    js.Tuple6(1624282200000.0,130.3,132.41,129.21,132.3,79663300),
    js.Tuple6(1624368600000.0,132.13,134.08,131.62,133.98,74783600),
    js.Tuple6(1624455000000.0,133.77,134.32,133.23,133.7,60214200),
    js.Tuple6(1624541400000.0,134.45,134.64,132.93,133.41,68711000),
    js.Tuple6(1624627800000.0,133.46,133.89,132.81,133.11,70783700),
    js.Tuple6(1624887000000.0,133.41,135.25,133.35,134.78,62111300),
    js.Tuple6(1624973400000.0,134.8,136.49,134.35,136.33,64556100),
    js.Tuple6(1625059800000.0,136.17,137.41,135.87,136.96,63261400),
    js.Tuple6(1625146200000.0,136.6,137.33,135.76,137.27,52485800),
    js.Tuple6(1625232600000.0,137.9,140,137.75,139.96,78852600),
    js.Tuple6(1625578200000.0,140.07,143.15,140.07,142.02,108181800),
    js.Tuple6(1625664600000.0,143.54,144.89,142.66,144.57,104911600),
    js.Tuple6(1625751000000.0,141.58,144.06,140.67,143.24,105575500),
    js.Tuple6(1625837400000.0,142.75,145.65,142.65,145.11,99890800),
    js.Tuple6(1626096600000.0,146.21,146.32,144,144.5,76299700),
    js.Tuple6(1626183000000.0,144.03,147.46,143.63,145.64,100827100),
    js.Tuple6(1626269400000.0,148.1,149.57,147.68,149.15,127050800),
    js.Tuple6(1626355800000.0,149.24,150,147.09,148.48,106820300),
    js.Tuple6(1626442200000.0,148.46,149.76,145.88,146.39,93251400),
    js.Tuple6(1626701400000.0,143.75,144.07,141.67,142.45,121434600),
    js.Tuple6(1626787800000.0,143.46,147.1,142.96,146.15,96350000),
    js.Tuple6(1626874200000.0,145.53,146.13,144.63,145.4,74993500),
    js.Tuple6(1626960600000.0,145.94,148.2,145.81,146.8,77338200),
    js.Tuple6(1627047000000.0,147.55,148.72,146.92,148.56,71447400),
    js.Tuple6(1627306200000.0,148.27,149.83,147.7,148.99,72434100),
    js.Tuple6(1627392600000.0,149.12,149.21,145.55,146.77,104818600),
    js.Tuple6(1627479000000.0,144.81,146.97,142.54,144.98,118931200),
    js.Tuple6(1627565400000.0,144.69,146.55,144.58,145.64,56699500),
    js.Tuple6(1627651800000.0,144.38,146.33,144.11,145.86,70382000),
    js.Tuple6(1627911000000.0,146.36,146.95,145.25,145.52,62880000),
    js.Tuple6(1627997400000.0,145.81,148.04,145.18,147.36,64786600),
    js.Tuple6(1628083800000.0,147.27,147.79,146.28,146.95,56368300),
    js.Tuple6(1628170200000.0,146.98,147.84,146.17,147.06,46397700),
    js.Tuple6(1628256600000.0,146.35,147.11,145.63,146.14,54067400),
    js.Tuple6(1628515800000.0,146.2,146.7,145.52,146.09,48908700),
    js.Tuple6(1628602200000.0,146.44,147.71,145.3,145.6,69023100),
    js.Tuple6(1628688600000.0,146.05,146.72,145.53,145.86,48493500),
    js.Tuple6(1628775000000.0,146.19,149.05,145.84,148.89,72282600),
    js.Tuple6(1628861400000.0,148.97,149.44,148.27,149.1,59318800),
    js.Tuple6(1629120600000.0,148.54,151.19,146.47,151.12,103296000),
    js.Tuple6(1629207000000.0,150.23,151.68,149.09,150.19,92229700),
    js.Tuple6(1629293400000.0,149.8,150.72,146.15,146.36,86326000),
    js.Tuple6(1629379800000.0,145.03,148,144.5,146.7,86960300),
    js.Tuple6(1629466200000.0,147.44,148.5,146.78,148.19,59947400),
    js.Tuple6(1629725400000.0,148.31,150.19,147.89,149.71,60131800),
    js.Tuple6(1629811800000.0,149.45,150.86,149.15,149.62,48606400),
    js.Tuple6(1629898200000.0,149.81,150.32,147.8,148.36,58991300),
    js.Tuple6(1629984600000.0,148.35,149.12,147.51,147.54,48597200),
    js.Tuple6(1630071000000.0,147.48,148.75,146.83,148.6,55721500),
    js.Tuple6(1630330200000.0,149,153.49,148.61,153.12,90956700),
    js.Tuple6(1630416600000.0,152.66,152.8,151.29,151.83,86453100),
    js.Tuple6(1630503000000.0,152.83,154.98,152.34,152.51,80313700),
    js.Tuple6(1630589400000.0,153.87,154.72,152.4,153.65,71115500),
    js.Tuple6(1630675800000.0,153.76,154.63,153.09,154.3,57808700),
    js.Tuple6(1631021400000.0,154.97,157.26,154.39,156.69,82278300),
    js.Tuple6(1631107800000.0,156.98,157.04,153.98,155.11,74420200),
    js.Tuple6(1631194200000.0,155.49,156.11,153.95,154.07,57305700),
    js.Tuple6(1631280600000.0,155,155.48,148.7,148.97,140646400),
    js.Tuple6(1631539800000.0,150.63,151.42,148.75,149.55,102404300),
    js.Tuple6(1631626200000.0,150.35,151.07,146.91,148.12,109296300),
    js.Tuple6(1631712600000.0,148.56,149.44,146.37,149.03,83281300),
    js.Tuple6(1631799000000.0,148.44,148.97,147.22,148.79,68034100),
    js.Tuple6(1631885400000.0,148.82,148.82,145.76,146.06,129868800),
    js.Tuple6(1632144600000.0,143.8,144.84,141.27,142.94,123478900),
    js.Tuple6(1632231000000.0,143.93,144.6,142.78,143.43,75834000),
    js.Tuple6(1632317400000.0,144.45,146.43,143.7,145.85,76404300),
    js.Tuple6(1632403800000.0,146.65,147.08,145.64,146.83,64838200),
    js.Tuple6(1632490200000.0,145.66,147.47,145.56,146.92,53477900),
    js.Tuple6(1632749400000.0,145.47,145.96,143.82,145.37,74150700),
    js.Tuple6(1632835800000.0,143.25,144.75,141.69,141.91,108972300),
    js.Tuple6(1632922200000.0,142.47,144.45,142.03,142.83,74602000),
    js.Tuple6(1633008600000.0,143.66,144.38,141.28,141.5,88934200),
    js.Tuple6(1633095000000.0,141.9,142.92,139.11,142.65,94639600),
    js.Tuple6(1633354200000.0,141.76,142.21,138.27,139.14,98322000),
    js.Tuple6(1633440600000.0,139.49,142.24,139.36,141.11,80861100),
    js.Tuple6(1633527000000.0,139.47,142.15,138.37,142,83221100),
    js.Tuple6(1633613400000.0,143.06,144.22,142.72,143.29,61732700),
    js.Tuple6(1633699800000.0,144.03,144.18,142.56,142.9,58718700),
    js.Tuple6(1633959000000.0,142.27,144.81,141.81,142.81,64452200),
    js.Tuple6(1634045400000.0,143.23,143.25,141.04,141.51,73035900),
    js.Tuple6(1634131800000.0,141.24,141.4,139.2,140.91,78762700),
    js.Tuple6(1634218200000.0,142.11,143.88,141.51,143.76,69907100),
    js.Tuple6(1634304600000.0,143.77,144.9,143.51,144.84,67885200),
    js.Tuple6(1634563800000.0,143.45,146.84,143.16,146.55,85589200),
    js.Tuple6(1634650200000.0,147.01,149.17,146.55,148.76,76378900),
    js.Tuple6(1634736600000.0,148.7,149.75,148.12,149.26,58418800),
    js.Tuple6(1634823000000.0,148.81,149.64,147.87,149.48,61421000),
    js.Tuple6(1634909400000.0,149.69,150.18,148.64,148.69,58883400),
    js.Tuple6(1635168600000.0,148.68,149.37,147.62,148.64,50720600),
    js.Tuple6(1635255000000.0,149.33,150.84,149.01,149.32,60893400),
    js.Tuple6(1635341400000.0,149.36,149.73,148.49,148.85,56094900),
    js.Tuple6(1635427800000.0,149.82,153.17,149.72,152.57,100077900),
    js.Tuple6(1635514200000.0,147.22,149.94,146.41,149.8,124850400),
    js.Tuple6(1635773400000.0,148.99,149.7,147.8,148.96,74588300),
    js.Tuple6(1635859800000.0,148.66,151.57,148.65,150.02,69122000),
    js.Tuple6(1635946200000.0,150.39,151.97,149.82,151.49,54511500),
    js.Tuple6(1636032600000.0,151.58,152.43,150.64,150.96,60394600),
    js.Tuple6(1636119000000.0,151.89,152.2,150.06,151.28,65414600),
    js.Tuple6(1636381800000.0,151.41,151.57,150.16,150.44,55020900),
    js.Tuple6(1636468200000.0,150.2,151.43,150.06,150.81,56787900),
    js.Tuple6(1636554600000.0,150.02,150.13,147.85,147.92,65187100),
    js.Tuple6(1636641000000.0,148.96,149.43,147.68,147.87,41000000),
    js.Tuple6(1636727400000.0,148.43,150.4,147.48,149.99,63632600),
    js.Tuple6(1636986600000.0,150.37,151.88,149.43,150,59222800),
    js.Tuple6(1637073000000.0,149.94,151.49,149.34,151,59256200),
    js.Tuple6(1637159400000.0,151,155,150.99,153.49,88807000),
    js.Tuple6(1637245800000.0,153.71,158.67,153.05,157.87,137827700),
    js.Tuple6(1637332200000.0,157.65,161.02,156.53,160.55,117305600),
    js.Tuple6(1637591400000.0,161.68,165.7,161,161.02,117467900),
    js.Tuple6(1637677800000.0,161.12,161.8,159.06,161.41,96041900),
    js.Tuple6(1637764200000.0,160.75,162.14,159.64,161.94,69463600),
    js.Tuple6(1637937000000.0,159.57,160.45,156.36,156.81,76959800),
    js.Tuple6(1638196200000.0,159.37,161.19,158.79,160.24,88748200),
    js.Tuple6(1638282600000.0,159.99,165.52,159.92,165.3,174048100),
    js.Tuple6(1638369000000.0,167.48,170.3,164.53,164.77,152052500),
    js.Tuple6(1638455400000.0,158.74,164.2,157.8,163.76,136739200),
    js.Tuple6(1638541800000.0,164.02,164.96,159.72,161.84,117938300),
    js.Tuple6(1638801000000.0,164.29,167.88,164.28,165.32,107497000),
    js.Tuple6(1638887400000.0,169.08,171.58,168.34,171.18,120405400),
    js.Tuple6(1638973800000.0,172.13,175.96,170.7,175.08,116998900),
    js.Tuple6(1639060200000.0,174.91,176.75,173.92,174.56,108923700),
    js.Tuple6(1639146600000.0,175.21,179.63,174.69,179.45,115228100),
    js.Tuple6(1639405800000.0,181.12,182.13,175.53,175.74,153237000),
    js.Tuple6(1639492200000.0,175.25,177.74,172.21,174.33,139380400),
    js.Tuple6(1639578600000.0,175.11,179.5,172.31,179.3,131063300),
    js.Tuple6(1639665000000.0,179.28,181.14,170.75,172.26,150185800),
    js.Tuple6(1639751400000.0,169.93,173.47,169.69,171.14,195432700),
    js.Tuple6(1640010600000.0,168.28,170.58,167.46,169.75,107499100),
    js.Tuple6(1640097000000.0,171.56,173.2,169.12,172.99,91185900),
    js.Tuple6(1640183400000.0,173.04,175.86,172.15,175.64,92135300),
    js.Tuple6(1640269800000.0,175.85,176.85,175.27,176.28,68356600),
    js.Tuple6(1640615400000.0,177.09,180.42,177.07,180.33,74919600),
    js.Tuple6(1640701800000.0,180.16,181.33,178.53,179.29,79144300),
    js.Tuple6(1640788200000.0,179.33,180.63,178.14,179.38,62348900),
    js.Tuple6(1640874600000.0,179.47,180.57,178.09,178.2,59773000),
    js.Tuple6(1640961000000.0,178.09,179.23,177.26,177.57,64062300),
    js.Tuple6(1641220200000.0,177.83,182.88,177.71,182.01,104487900),
    js.Tuple6(1641306600000.0,182.63,182.94,179.12,179.7,99310400),
    js.Tuple6(1641393000000.0,179.61,180.17,174.64,174.92,94537600),
    js.Tuple6(1641479400000.0,172.7,175.3,171.64,172,96904000),
    js.Tuple6(1641565800000.0,172.89,174.14,171.03,172.17,86580100),
    js.Tuple6(1641825000000.0,169.08,172.5,168.17,172.19,106765600),
    js.Tuple6(1641911400000.0,172.32,175.18,170.82,175.08,76138300),
    js.Tuple6(1641997800000.0,176.12,177.18,174.82,175.53,74805200),
    js.Tuple6(1642084200000.0,175.78,176.62,171.79,172.19,84505800),
    js.Tuple6(1642170600000.0,171.34,173.78,171.09,173.07,80355000),
    js.Tuple6(1642516200000.0,171.51,172.54,169.41,169.8,90956700),
    js.Tuple6(1642602600000.0,170,171.08,165.94,166.23,94815000),
    js.Tuple6(1642689000000.0,166.98,169.68,164.18,164.51,91420500),
    js.Tuple6(1642775400000.0,164.42,166.33,162.3,162.41,122848900),
    js.Tuple6(1643034600000.0,160.02,162.3,154.7,161.62,162294600),
    js.Tuple6(1643121000000.0,158.98,162.76,157.02,159.78,115798400),
    js.Tuple6(1643207400000.0,163.5,164.39,157.82,159.69,108275300),
    js.Tuple6(1643293800000.0,162.45,163.84,158.28,159.22,121954600),
    js.Tuple6(1643380200000.0,165.71,170.35,162.8,170.33,179935700),
    js.Tuple6(1643639400000.0,170.16,175,169.51,174.78,115541600),
    js.Tuple6(1643725800000.0,174.01,174.84,172.31,174.61,86213900),
    js.Tuple6(1643812200000.0,174.75,175.88,173.33,175.84,84914300),
    js.Tuple6(1643898600000.0,174.48,176.24,172.12,172.9,89254800),
  )
}
