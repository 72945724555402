package com.nikolastojiljkovic.algot.client.components

import com.nikolastojiljkovic.algot.client.stubs.*
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.lightweightCharts.mod.{IChartApi, createChart}
import io.laminext.domext.ResizeObserverEntry
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*
import org.scalajs.dom

import facades.lightweightCharts.anon._
import facades.lightweightCharts.mod.DeepPartial

import scala.scalajs.js
import scala.scalajs.js.annotation.JSExportAll

object LightweightCharts extends Laminar {
  // Laminar reactive element

  import com.raquo.laminar.nodes.ReactiveHtmlElement

  type REF = dom.html.Element
  type El = ReactiveHtmlElement[REF]
  type ModFunction = LightweightCharts.type => Mod[El]

  case class Props(theme: String)

  val data: js.Array[js.Any] = SampleData.aapl.map(d => {
//    val date = new js.Date(d._1.toLong)
//    val dateFormatted = Seq(
//      date.getUTCFullYear().toString,
//      f"${(date.getUTCMonth() + 1).toInt}%02d",
//      f"${date.getUTCDay().toInt}%02d"
//    ).mkString("-")
    js.Dynamic.literal(time = (d._1 / 1000).toInt, value = d._2)
  })

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val ed = Var(Option.empty[IChartApi])

    val modifiers = mods.map(_ (LightweightCharts)) :+
      onMountCallback[El] { ctx =>
        val chart = createChart(ctx.thisNode.ref, DeepPartialChartOptions()
//          .setWidth(1.asInstanceOf[DeepPartial[Double]])
//          .setHeight(1.asInstanceOf[DeepPartial[Double]])
        )
        val lineSeries = chart.addLineSeries()
        lineSeries.setData(data)

        ed.set(Some(chart))

        given Owner = ctx.owner

        props.map(_.theme).addObserver(Observer {
          case "dark" =>
            chart.applyOptions(
              DeepPartialChartOptions()
                .setLayout(
                  DeepPartialLayoutOptions()
                    .setBackgroundColor("#1e1e1e".asInstanceOf[DeepPartial[String]])
                    .setTextColor("#d1d4dc".asInstanceOf[DeepPartial[String]])
                )
                .setGrid(
                  DeepPartialGridOptions()
                    .setHorzLines(
                      DeepPartialGridLineOption()
                        .setColor("#d4d4d4".asInstanceOf[DeepPartial[String]])
                    )
                    .setVertLines(
                      DeepPartialGridLineOption()
                        .setColor("#d4d4d4".asInstanceOf[DeepPartial[String]])
                    )
                )
            )
          case _ =>
            chart.applyOptions(
              DeepPartialChartOptions()
                .setLayout(
                  DeepPartialLayoutOptions()
                    .setBackgroundColor("#ffffff".asInstanceOf[DeepPartial[String]])
                    .setTextColor("#000000".asInstanceOf[DeepPartial[String]])
                )
                .setGridUndefined
            )
        })
      } :+
      onUnmountCallback[El] { thisNode =>
        ed.now().foreach(_.remove())
        ed.set(None)
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        ed.now().foreach(chart => {
          import facades.lightweightCharts.anon.DeepPartialChartOptions
          import facades.lightweightCharts.mod.DeepPartial

          chart.applyOptions(
            DeepPartialChartOptions()
              .setWidth(entry.contentRect.width.asInstanceOf[DeepPartial[Double]])
              .setHeight(entry.contentRect.height.asInstanceOf[DeepPartial[Double]])
          )
        })
      }

    div(modifiers: _*)
  }
}
