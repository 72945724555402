package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinHorizontalLayout.vaadinHorizontalLayoutMod.HorizontalLayout as VaadinHorizontalLayout

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object HorizontalLayout extends Laminar {

  facades.vaadinHorizontalLayout.vaadinHorizontalLayoutRequire

  type REF = dom.html.Element with VaadinHorizontalLayout
  type El = ReactiveHtmlElement[REF]
  type ModFunction = HorizontalLayout.type => Mod[El]

  private val tag = customHtmlTag[REF]("vaadin-horizontal-layout")

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (HorizontalLayout)): _*)
  }
}