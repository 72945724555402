package com.nikolastojiljkovic.algot.client.components

import com.nikolastojiljkovic.algot.client.jsObject
import com.nikolastojiljkovic.algot.client.stubs.*
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.chartJs.mod.*
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*
import monocle.syntax.all._
import org.scalajs.dom
import org.scalajs.dom.HTMLElement
import scala.scalajs.js
import scala.scalajs.js.annotation.{JSExportAll, JSImport}
object ChartsJS extends Laminar {

  registerables.foreach(Chart.register(_))

  type AlignedData = js.Array[js.Array[scala.scalajs.js.UndefOr[Double | Int]]]

  // Laminar reactive element

  import com.raquo.laminar.api.L.{*, given}
  import com.raquo.laminar.nodes.ReactiveHtmlElement

  type REF = dom.html.Canvas
  type El = ReactiveHtmlElement[REF]
  type ModFunction = ChartsJS.type => Mod[El]

  case class Props(theme: String)

  case class State(theme: String = "")

  val data: AlignedData = js.Array(
    // timestamps
    SampleData.aapl.map(_._1 / 1000),
    // close
    SampleData.aapl.map(_._4),
    // volume
    SampleData.aapl.map(_._6),
  )

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val ed = Var(Option.empty[Chart[_, _, _]])
    val state = Var(State())

    val modifiers = mods.map(_ (ChartsJS)) :+
      onMountCallback[El] { ctx =>
        import facades.chartJs.utilsMod.DeepPartial

        // <canvas id="myChart" width="600" height="400"></canvas>
        val chart: Chart[_, _, _] = new Chart(
          ctx.thisNode.ref,
          ChartConfiguration(
            data = ChartData(
              datasets = js.Array(
                ChartDatasetProperties(SampleData.aapl.map(_._4))
              )
            ).setLabels(
              SampleData.aapl.map(_._1)
            ),
            `type` = "line"
          )
            .setOptions({
              val x = jsObject[CoreChartOptions[String]]
              x.maintainAspectRatio = false
              x.responsive = true
              x.asInstanceOf[ChartOptions[String]]
            })
        )
        ed.set(Some(chart))

        given Owner = ctx.owner

        state.signal.addObserver(Observer { s =>
          // chart.redraw(false)
        })

        props.map(_.theme).addObserver(Observer { theme =>
          state.update(_.focus(_.theme).modify(_ => theme))
        })
      } :+
      onUnmountCallback[El] { thisNode =>
        ed.now().foreach(_.destroy())
        ed.set(None)
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        ed.now().foreach(chart => {
          // chart.setSize(Size(entry.contentRect.height, entry.contentRect.width))
        })
      }

    canvas(modifiers: _*)
  }
}
