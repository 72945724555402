package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.{Codec, StringAsIsCodec}
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinIcon.vaadinIconMod.{Icon => VaadinIcon}
import org.scalablytyped.runtime.StObject

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object Icon {

  facades.vaadinIcon.vaadinIconRequire

  type Ref = dom.html.Element with VaadinIcon
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = Icon.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-icon")

  val icon: HtmlAttr[String] = customHtmlAttr("icon", StringAsIsCodec)

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (Icon)): _*)
  }
}