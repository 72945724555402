package com.nikolastojiljkovic.algot.client.components.vaadin

import com.nikolastojiljkovic.algot.client.components.vaadin.AppLayout.PrimarySection
import com.raquo.domtypes.generic.codecs.{Codec, StringAsIsCodec}
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinTabs.vaadinTabsVaadinTabsMod.Tabs as VaadinTabs

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object Tabs {

  facades.vaadinTabs.vaadinTabsRequire

  type Ref = dom.html.Element with VaadinTabs
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = Tabs.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-tabs")

  val slot: HtmlAttr[PrimarySection] = customHtmlAttr("slot", summon[Codec[PrimarySection, String]])

  enum Orientation:
    case horizontal, vertical

  val orientation: HtmlAttr[Orientation] = customHtmlAttr("orientation", summon[Codec[Orientation, String]])

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (Tabs)): _*)
  }
}