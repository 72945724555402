package com.nikolastojiljkovic.algot.client.components

import com.nikolastojiljkovic.algot.client.stubs.*
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.highcharts.anon.TypeofHighchartsAddEvent
import facades.highcharts.highchartsRequire as HighchartsExport
import facades.highcharts.*
import facades.highcharts.mod.*
import org.scalajs.dom
import io.laminext.domext.ResizeObserverEntry
import io.laminext.syntax.core.*

import scala.scalajs.js

object Highcharts extends Laminar {
  // install required modules
  private val highchart = HighchartsExport.asInstanceOf[TypeofHighchartsAddEvent]
  boostMod.default(highchart)
  dataMod.default(highchart)
  datagroupingMod.default(highchart)
  indicatorsAllMod.default(highchart)
  dragPanesMod.default(highchart)
  annotationsAdvancedMod.default(highchart)
  priceIndicatorMod.default(highchart)
  fullScreenMod.default(highchart)
  stockMod.default(highchart)
  stockToolsMod.default(highchart)
  sonificationMod.default(highchart)

  // sample data
  val d = SeriesOhlcOptions((), (), "ohlc")
    .setData(
      SampleData.aapl.map(d =>
        js.Tuple5(d._1, d._2, d._3, d._4, d._5)
      )
    )
    .setId("sample-series")
    .setName("Sample series")
  val data = js.Array(d.asInstanceOf[SeriesOptionsType])

  val darkTheme = Options()
    .setChart(
      ChartOptions()
        .setBackgroundColor("#1f2428")
    )

  val lightTheme = Options()
    .setChart(
      ChartOptions()
        .setBackgroundColor("#ffffff")
    )

  // @see https://www.highcharts.com/demo/stock/basic-line
  val options = Options()
    .setTitle(
      TitleOptions()
        .setText("Sample chart!")
    )
    .setNavigator(
      NavigatorOptions().setEnabled(true)
    )
    .setScrollbar(
      ScrollbarOptions().setEnabled(true)
    )
    .setRangeSelector(
      RangeSelectorOptions().setEnabled(true)
    )
    .setXAxis(
      XAxisOptions()
        .setType(AxisTypeValue.datetime)
        .setCategoriesUndefined
        .setStartOnTick(false)
        .setEndOnTick(false)
        .setOrdinal(true)
        .setMinPadding(0.0)
        .setMaxPadding(0.0)
        .setOverscroll(0.0)
        .setTitle(XAxisTitleOptions().setTextNull)
        .setLabels(XAxisLabelsOptions().setOverflow(OptionsOverflowValue.justify))
        .setShowLastLabel(true)
    )
    .setYAxis(
      YAxisOptions()
        .setLabels(YAxisLabelsOptions()
          .setAlign(AlignValue.left)
          .setY(-2.0)
        )
        .setOpposite(true)
        .setShowLastLabel(false)
        .setTitle(YAxisTitleOptions().setTextNull)
        .setResize(YAxisResizeOptions().setEnabled(true)),
    )

  // Laminar reactive element

  import com.raquo.laminar.nodes.ReactiveHtmlElement

  type REF = dom.html.Element
  type El = ReactiveHtmlElement[REF]
  type ModFunction = Highcharts.type => Mod[El]

  case class Props(theme: String)

  def apply(props: Signal[Props], mods: ModFunction*): El = {
    val ed = Var(Option.empty[Chart_])

    val modifiers = mods.map(_ (Highcharts)) :+
      onMountCallback[El] { ctx =>
        val c = chart(ctx.thisNode.ref, options.setSeries(data))
        ed.set(Some(c))

        given Owner = ctx.owner

        props.map(_.theme).addObserver(Observer { theme =>
          // @todo listen only on the part of the props!
          val chartTheme = theme match {
            case "dark" => darkTheme
            case _ => lightTheme
          }

          // NOTE: This is officially documented way of theming Highcharts
          // However, currently simple CSS based styling is used because of these important factors:
          // * Highcharts dark theme looks awkward with stocks tools
          // * Usage of Highcharts is not definite atm
          // @see resources/index.scss
          // @see node_modules/highcharts/css/themes/dark-unica.scss
          // @see node_modules/highcharts/es-modules/Extensions/Themes/DarkUnica.js
          // @see https://www.highcharts.com/demo/stock/stock-tools-gui/dark-unica
          if (false) {
            facades.highcharts.mod.setOptions(chartTheme)
            ed.now().foreach(_.destroy())
            val c = chart(ctx.thisNode.ref, options.setSeries(data))
            ed.set(Some(c))
          }
        })
      } :+
      onUnmountCallback[El] { thisNode =>
        ed.now().foreach(_.destroy())
        ed.set(None)
      } :+
      resizeObserver --> { (entry: ResizeObserverEntry) =>
        ed.now().foreach(_.reflow())
      }

    div(modifiers: _*)
  }
}
