package com.nikolastojiljkovic.algot.client.components.vaadin

import com.nikolastojiljkovic.algot.client.components.vaadin.AppLayout.PrimarySection
import com.raquo.domtypes.generic.codecs.{Codec, StringAsIsCodec}
import com.raquo.laminar.api.L.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinTabs.vaadinTabMod.Tab as VaadinTab

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object Tab {

  facades.vaadinTabs.vaadinTabsRequire

  type Ref = dom.html.Element with VaadinTab
  type El = ReactiveHtmlElement[Ref]
  type ModFunction = Tab.type => Mod[El]

  private val tag = customHtmlTag[Ref]("vaadin-tab")

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (Tab)): _*)
  }
}