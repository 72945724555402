package com.nikolastojiljkovic.algot.client.components.vaadin

import com.raquo.domtypes.generic.codecs.*
import com.raquo.laminar.nodes.ReactiveHtmlElement
import org.scalajs.dom
import com.nikolastojiljkovic.scalajs.facades
import com.raquo.laminar.Laminar
import facades.vaadinVerticalLayout.vaadinVerticalLayoutMod.VerticalLayout as VaadinVerticalLayout

import scala.scalajs.js
import scala.scalajs.js.annotation.JSImport

object VerticalLayout extends Laminar {

  facades.vaadinVerticalLayout.vaadinVerticalLayoutRequire

  type REF = dom.html.Element with VaadinVerticalLayout
  type El = ReactiveHtmlElement[REF]
  type ModFunction = VerticalLayout.type => Mod[El]

  private val tag = customHtmlTag[REF]("vaadin-vertical-layout")

  def apply(mods: ModFunction*): El = {
    tag(mods.map(_ (VerticalLayout)): _*)
  }
}