import {css, registerStyles} from '@vaadin/vaadin-themable-mixin/vaadin-themable-mixin.js';

// @see https://demo.vaadin.com/lumo-editor/
const theme = css`
html {
  --lumo-border-radius: 0.125em;

  --lumo-primary-text-color: rgb(106, 115, 125);
  --lumo-primary-color-50pct: rgba(106, 115, 125, 0.5);
  --lumo-primary-color-10pct: rgba(106, 115, 125, 0.1);
  --lumo-primary-color: #6a737d;

  --lumo-error-text-color: rgb(215, 58, 73);
  --lumo-error-color-50pct: rgba(215, 58, 73, 0.5);
  --lumo-error-color-10pct: rgba(215, 58, 73, 0.1);
  --lumo-error-color: #d73a49;

  --lumo-success-text-color: rgb(40, 167, 69);
  --lumo-success-color-50pct: rgba(40, 167, 69, 0.5);
  --lumo-success-color-10pct: rgba(40, 167, 69, 0.1);
  --lumo-success-color: #28a745;
}

[theme~="dark"] {
  --lumo-base-color: #1f2428;

  --lumo-primary-text-color: rgb(209, 213, 218);
  --lumo-primary-color-50pct: rgba(209, 213, 218, 0.5);
  --lumo-primary-color-10pct: rgba(209, 213, 218, 0.1);
  --lumo-primary-color: #d1d5da;

  --lumo-error-text-color: rgb(215, 58, 73);
  --lumo-error-color-50pct: rgba(215, 58, 73, 0.5);
  --lumo-error-color-10pct: rgba(215, 58, 73, 0.1);
  --lumo-error-color: #d73a49;

  --lumo-success-text-color: rgb(40, 167, 69);
  --lumo-success-color-50pct: rgba(40, 167, 69, 0.5);
  --lumo-success-color-10pct: rgba(40, 167, 69, 0.1);
  --lumo-success-color: #28a745;
}
`;

// fix for the context menu crop bellow the navbar
registerStyles('vaadin-app-layout', css`
[part='navbar'] {
    z-index: 0 !important;
}
[content] {
    overflow: visible !important;
}
`, {moduleId: 'override'})

registerStyles('vaadin-split-layout', css`
:host {
    overflow: visible !important;
}
`, {moduleId: 'override'})

/*
// tweak for the icon size, see vaadin-icon in index.scss
registerStyles('vaadin-icon', css`
:host {
    box-sizing: border-box;
    margin-inline-end: var(--lumo-space-m);
    margin-inline-start: var(--lumo-space-xs);
    padding: var(--lumo-space-xs);
}
`, {moduleId: 'override'})
*/

// fix for the Edit -> E... on menu resize
registerStyles('vaadin-menu-bar', css`
[part='menu-bar-button'] {
    width: auto !important;
}
`, {moduleId: 'override'})

// import '@vaadin/vaadin-lumo-styles/all-imports.js';
import {color} from '@vaadin/vaadin-lumo-styles/color.js';
import {typography} from '@vaadin/vaadin-lumo-styles/typography.js';
import {utility} from '@vaadin/vaadin-lumo-styles/utility.js';
import '@vaadin/icons';

[color, typography, utility, theme].forEach(function (css, index) {
    const $tpl = document.createElement('template');
    $tpl.innerHTML = `<style>${css.toString().replace(':host', 'html')}</style>`;
    // var div = document.createElement('div');
    // div.appendChild( $tpl.content.cloneNode(true) );
    // window.console.log(div.innerHTML);
    document.head.appendChild($tpl.content);
})

// // Import the <custom-style> element from Polymer and include
// // the style sheets in the global scope
// import '@polymer/polymer/lib/elements/custom-style.js';

// NOTE: For some reason the order of includes is not respected, if put into index.css Vaadin theme will be overriden by defaults!
import './index.scss';

// Prepare global environment for Scala.js implementation
import './client.ts';
